const hideEditorReducerDefaultState = false;

const hideEditorReducer = (
  state = hideEditorReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_HIDE_EDITOR':
      return action.hide
    default:
      return state;
  }
}

export default hideEditorReducer;
