import { startInitAccount, startUnsetAccount } from './account';
import { signout, startSigninWithRoles } from './auth';
import { startInitTrialActivity, startUnsetTrialActivity } from './trialActivity';


export const setInitialState = (uid, roles) => {
  return (dispatch, getState) => {
    // console.log('start setInitialState');
    dispatch(startSigninWithRoles(uid, roles));
    dispatch(startInitTrialActivity(uid));
    return dispatch(startInitAccount(uid))
  }
}

export const unsetInitialState = () => {
  return (dispatch) => {
    // console.log('start unsetInitialState');
    dispatch(signout());
    dispatch(startUnsetTrialActivity());
    return dispatch(startUnsetAccount())
  }
}

export default setInitialState;