import React from 'react';
import { copyMessage } from '../helpers/copying';
import { Button, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { startSetCopySnackbar } from '../actions/copySnackbar';
import { FileCopy } from '@material-ui/icons';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import moment from 'moment';


const CopyButton = ({dispatch, message, auth, account}) => {

  const handleCopy = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      const formattedMessage = {
        ...message,
        lastSaved: message.lastSaved ? moment(message.lastSaved).format("MMM Do YYYY") : '',
        created: message.created ? moment(message.created).format("MMM Do YYYY") : ''
      }
      if (message) { amplitude.logEvent('Copy message', { message: formattedMessage }) };
    }
    copyMessage(dispatch, message, startSetCopySnackbar);
  }

  return (
    <Tooltip title="Copy message">
      <Button
        startIcon={<FileCopy />}
        color="inherit"
        onClick={handleCopy}
        variant="outlined"
      >
        Copy
      </Button>
    </Tooltip>
  )
}

const mapStateToProps = (state, props) => {
  return {
    message: state.message.present,
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(CopyButton);
