import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TechniqueList from '../components/TechniqueList';
import { startSetExpandedBox } from '../actions/expandedBox';
import { startUnsetActiveSuggestion } from '../actions/activeSuggestion';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import { defaultNumSuggestionsShown, additionalSuggestionsShown } from '../helpers/constants';
import { hideHighlightButtons } from '../helpers/highlightButtons';
import { initJqueryHighlightPluginToNone } from '../helpers/highlightText';
import { startSetHighlightMode } from '../actions/highlightMode';


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
    marginRight: 8
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const TechniqueBox = ({dispatch, category, categoryId, expandedBox, auth, account, activeSuggestion, activeCategories, highlightMode}) => {

  const classes = useStyles();

  // handle show more or less of the suggestions
  const [suggestionsArray, setSuggestionsArray] = useState(Object.entries(category[1].suggestions));
  const [numSuggestionsShown, setNumSuggestionsShown] = useState(defaultNumSuggestionsShown);
  const showMore = () => {
    setNumSuggestionsShown(numSuggestionsShown + additionalSuggestionsShown);
  }
  const resetNumSuggestionsShown = () => {
    setNumSuggestionsShown(3);
  }

  // Reorder the suggestions when a non-visible suggestion is chosen
  useEffect(() => {
    if (     categoryId === expandedBox
          && activeSuggestion
          && activeSuggestion.suggestionId
          && activeSuggestion.suggestionId !== undefined
          && suggestionsArray.findIndex((sug) => {return sug[0] === activeSuggestion.suggestionId}) >= numSuggestionsShown
        ) {
      const first = suggestionsArray.filter((sug) => { return sug[0] === activeSuggestion.suggestionId });
      const remaining = suggestionsArray.filter((sug) => { return sug[0] !== activeSuggestion.suggestionId });
      const result = [...first, ...remaining];
      setSuggestionsArray(result);
    }
  }, [expandedBox, activeSuggestion, categoryId, suggestionsArray, numSuggestionsShown])


  const handleChange = () => {
    if (categoryId === expandedBox) {
      dispatch(startSetExpandedBox(false))
      resetNumSuggestionsShown();
    } else {
      if (amplitudeFlag) {
        if (auth.uid) { amplitude.setUserId(auth.uid) };
        if (category) { amplitude.logEvent('Open category', { category: category }) };
      }
      dispatch(startSetExpandedBox(categoryId));
    }
    if (activeSuggestion) {
      initJqueryHighlightPluginToNone();
      dispatch(startUnsetActiveSuggestion())
    }
    if (highlightMode === 'alternatives' && activeSuggestion) {
      hideHighlightButtons(activeCategories, dispatch);
      initJqueryHighlightPluginToNone();
      dispatch(startSetHighlightMode('off'));
    } 
  }


  return (
    <div className="technique-box" id={categoryId}>
      <Accordion expanded={categoryId === expandedBox} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{category[1].categoryName}</Typography>
          <Typography className={classes.secondaryHeading}>{category[1].categoryComment}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {category[1].suggestions ? <TechniqueList
            // suggestions={category[1].suggestions}
            categoryField={category[1].categoryField}
            totalSuggestions={suggestionsArray.length}
            showMore={showMore}
            visibleSuggestionsArray={suggestionsArray.slice(0, numSuggestionsShown)}
            numSuggestionsShown={numSuggestionsShown}
          /> : ''}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    expandedBox: state.expandedBox,
    auth: state.auth,
    account: state.account,
    activeSuggestion: state.activeSuggestion,
    activeCategories: state.activeCategories,
    highlightMode: state.highlightMode
  }
}

export default connect(mapStateToProps)(TechniqueBox);