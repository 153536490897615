const messagesReducerDefaultState = {}

const messagesReducer = (
  state = messagesReducerDefaultState, action
) => {
  switch (action.type) {
    case 'INIT_MESSAGES':
      return action.messages;
    case 'UNSET_MESSAGES':
      return messagesReducerDefaultState;
    case 'SAVE_MESSAGE':
      return {
        ...state,
        [action.messageId]: {
          ...state[action.messageId],
          ...action.updates
        }
      }
    case 'REMOVE_MESSAGE':
      const res = { ...state };
      delete res[action.messageId];
      return res;
    default:
      return state;
  }
}

export default messagesReducer;
