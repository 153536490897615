import React, { useState } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { startSaveCategory } from '../../actions/categories';
import { replaceDumbText } from '../../helpers/editText';


const CreateCategory = ({ dispatch }) => {

  const [categoryName, setCategoryName] = useState('');
  const [categoryComment, setCategoryComment] = useState('');
  const [categoryField, setCategoryField] = useState('');
  const [categoryOrder, setCategoryOrder] = useState();



  const handleClick = () => {
    const categoryId = uuid();
    dispatch(startSaveCategory(categoryId, { categoryName, categoryComment, categoryOrder, categoryField }));
    setCategoryName('');
    setCategoryName('');
    setCategoryField('');
    setCategoryOrder('');
  }

  return (
    <div>
      <div className="admin-create-category">
        <div className="admin-text-large">
          <TextField label='categoryName' id='category-name' fullWidth={true} onChange={(e) => { setCategoryName(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-medium">
          <TextField label='categoryComment' id='category-name' fullWidth={true} onChange={(e) => { setCategoryComment(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-medium">
          <FormControl>
            <InputLabel id="demo-simple-select-label">categoryField</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categoryField}
              onChange={(e) => {setCategoryField(e.target.value)}}
            >
              <MenuItem value="subject" key="subject">subject</MenuItem>
              <MenuItem value="body" key="body">body</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="admin-text-small">
          <TextField label='categoryOrder' id='category-order' fullWidth={true} onChange={(e) => { setCategoryOrder(e.target.value) }} />
        </div>
        <div>
          <Button id='add-category' variant="contained" color="primary" onClick={() => { handleClick() }}>Create Category</Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps)(CreateCategory);