


// 800 pixel of screen height was right for 30 rows

// Also consider responsive grid issue for mobile

/* --- ROWS in Body --- */

export const returnTextAreaRows = () => {
  let screenHeight = document.documentElement.clientHeight
  let rows;
  if (screenHeight > 950) {
    rows = 39;
  } else if (screenHeight > 900) {
    rows = 36;
  } else if (screenHeight > 800) {
    rows = 30;
  } else if (screenHeight > 700) {
    rows = 25;
  } else if (screenHeight > 600) {
    rows = 20;
  } else if (screenHeight > 500) {
    rows = 15;
  } else if (screenHeight > 400) {
    rows = 10;
  } else {
    rows = 5;
  }
  return rows;
}

export const setTextAreaSize = () => {
  const bodyField = document.getElementById('body-text-field')
  bodyField.rows = returnTextAreaRows();
}

export const changeTextAreaOnResize = () => {
  window.onresize = setTextAreaSize;
}

/* --- TemplateNameLength in TemplateName in Toolbar --- */

export const returnTemplateNameLength = () => {
  let screenWidth = document.documentElement.clientWidth;
  let length;
  if (screenWidth < 700) {
    length = 0;
  } else if (screenWidth < 850) {
    length = 20;
  } else if (screenWidth < 900) {
    length = 30;
  } else if (screenWidth < 1000) {
    length = 50;
  } else if (screenWidth < 1100) {
    length = 70;
  } else {
    length = 90;
  }
  return length;
}