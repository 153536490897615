import moment from 'moment';

export const formatDateForTable = (date) => {
  if (!date) {
    return '';
  } else {
    let result = moment(date).format("MMM Do YYYY");
    const now = moment(Date.now()).format("MMM Do YYYY");
    return result !== now ? result : moment(date).startOf('hour').fromNow();
  }
}