import React, {useState, useEffect} from 'react';
import {FormLabel, TextField} from '@material-ui/core';
import { startSetMessageSubject } from '../actions/message';
import { connect } from 'react-redux';
import { startIncrementChangesSinceSave } from '../actions/changesSinceSave';
import { useDebouncedCallback } from 'use-debounce';
import { triggerInputEvent, initJqueryHighlightPluginToNone } from '../helpers/highlightText';
import { startUnsetActiveSuggestion } from '../actions/activeSuggestion';
import { startSetMessageLastUpdated } from '../actions/messageLastUpdated';


const Subject = ({ subject, dispatch, highlightMode, activeSuggestion, messageLastUpdated, messageLastModified }) => {

  // The value shown in the text area
  const [text, setText] = useState(subject)

  useEffect(() => {
    setText(subject);
  }, [subject])

  const handleChange = async (text) => {

    if (messageLastUpdated) {
      dispatch(startSetMessageLastUpdated(false));
      const myField = document.getElementById('subject-text-field');
      const index = myField.selectionStart;
      await initJqueryHighlightPluginToNone();
      myField.focus();
      setTimeout(() => { myField.setSelectionRange(index, index) }, 10);
    } else if (activeSuggestion) {
      const myField = document.getElementById('subject-text-field');
      const index = myField.selectionStart;
      initJqueryHighlightPluginToNone();
      dispatch(startUnsetActiveSuggestion())
      // Next 2 lines are because jQuery plugin causes the field to unfocus.
      // Clean it up later.
      myField.focus();
      setTimeout(() => { myField.setSelectionRange(index, index) }, 10);
    }

    setText(text);
    updateStore(text);
  }

  // Debounce (delay) the update to the store
  const [updateStore] = useDebouncedCallback(
    (text) => {
      // If the messageContent has not been updated within the past 1,000 ms, update messageBody.
      if (messageLastModified < Date.now() - 1000) {
        dispatch(startSetMessageSubject(text));
        dispatch(startIncrementChangesSinceSave());
      }
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    if ((highlightMode === 'highlight' || highlightMode === 'alternatives') && subject !== text) {
      const update = async () => {
        await setText(subject);
        triggerInputEvent('subject-text-field');
      }
      update();
    }
  }, [subject, text, highlightMode])

  return (
    <div className="subject">
      <form noValidate autoComplete="off">
        <span className="subject__line" >
          <FormLabel >Subject:</FormLabel>
          <div id='subject-field' className="subject__text-field-container">
            <TextField
              fullWidth={true}
              multiline={true}
              id="subject-text-field"
              value={text}
              onChange={(e) => {handleChange(e.target.value)}}
            />
          </div>
        </span>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    subject: state.message.present.subject,
    messageLastModified: state.message.present.time,
    highlightMode: state.highlightMode,
    activeSuggestion: state.activeSuggestion,
    messageLastUpdated: state.messageLastUpdated,
  }
}

export default connect(mapStateToProps)(Subject);