import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { contactPath, messagesPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import Navbar from './Navbar';


const MessageNotAvailable = ({fallbackMessage, auth, account, navbar}) => {

  if (amplitudeFlag) {
    if (auth.uid) { amplitude.setUserId(auth.uid) };
    amplitude.logEvent('Show fallback page');
  }

  return (
    <div>
      {navbar ? <Navbar /> : ''}
      <div className="fallback">
        <div className="fallback__title">
          <Typography variant="h4" gutterBottom>Something went wrong.</Typography>
        </div>
        <Typography gutterBottom={true}>Either this page does not exist or you don't have permission to access it.</Typography>
        <Typography gutterBottom={true}>Check for a typo in the url.</Typography>
        <div className="fallback__buttons">
          <Button
            startIcon={<ArrowBack />}
            style={{ borderRadius: 25, marginLeft: 16, marginRight: 16 }}
            variant="contained"
            onClick={() => { history.push(messagesPath) }}
          >Back to Your Messages
          </Button>
          <Button
            // startIcon={<ArrowBack />}
            style={{ borderRadius: 25, marginLeft: 16, marginRight: 16 }}
            variant="contained"
            onClick={() => { history.push(contactPath) }}
          >Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(MessageNotAvailable); 