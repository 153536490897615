import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import templatesAboutReducer from '../reducers/templatesAbout';
import templatesContentReducer from '../reducers/templatesContent';
import messageReducer from '../reducers/message';
import changesSinceSaveReducer from '../reducers/changesSinceSave';
import messagesReducer from '../reducers/messages';
import highlightModeReducer from '../reducers/highlightMode';
import categoriesReducer from '../reducers/categories';
import expandedBoxReducer from '../reducers/expandedBox';
import activeSuggestionReducer from '../reducers/activeSuggestion';
import copySnackbarReducer from '../reducers/copySnackbar';
import hideEditorReducer from '../reducers/hideEditor';
import saveSnackbarReducer from '../reducers/saveSnackbar';
import accountReducer from '../reducers/account';
import activeCategoriesReducer from '../reducers/activeCategories';
import messageLastUpdatedReducer from '../reducers/messageLastUpdated';
import trialActivityReducer from '../reducers/trialActivity';

// Store creation
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      templatesAbout: templatesAboutReducer,
      templatesContent: templatesContentReducer,
      message: messageReducer,
      changesSinceSave: changesSinceSaveReducer,
      messages: messagesReducer,
      highlightMode: highlightModeReducer,
      categories: categoriesReducer,
      expandedBox: expandedBoxReducer,
      activeSuggestion: activeSuggestionReducer,
      copySnackbar: copySnackbarReducer,
      hideEditor: hideEditorReducer,
      saveSnackbar: saveSnackbarReducer,      
      account: accountReducer,
      activeCategories: activeCategoriesReducer,
      messageLastUpdated: messageLastUpdatedReducer,
      trialActivity: trialActivityReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}