import { replaceDumbText } from '../helpers/editText';

const setMessageSubject = (subject) => ({
  type: 'SET_MESSAGE_SUBJECT',
  subject
});

export const startSetMessageSubject = (subject) => {
  return (dispatch) => {
    const subj = replaceDumbText(subject);
    dispatch(setMessageSubject(subj));
  }
}

const setMessageBody = (body) => ({
  type: 'SET_MESSAGE_BODY',
  body
});

export const startSetMessageBody = (body) => {
  return (dispatch) => {
    const bod = replaceDumbText(body);
    dispatch(setMessageBody(bod));
  }
}

const setMessageContent = (message) => ({
  type: 'SET_MESSAGE_CONTENT',
  message
});

export const startSetMessageContent = (message) => {
  return (dispatch) => {
    const mes = {
      ...message,
      subject: replaceDumbText(message.subject),
      body: replaceDumbText(message.body)
    }
    dispatch(setMessageContent(mes));
  }
}