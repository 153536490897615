import React, {useState} from 'react';
import { Typography, Button, CardActions, CardContent, Card, TextField } from '@material-ui/core';
import { googleSheetsEmailScriptUrl } from '../helpers/constants';
import {validateEmail} from '../helpers/formValidation';

export default ({ incrementScreen, decrementScreen }) => {

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (validateEmail(email)) {
      sendEmailToGoogleSheets();
      incrementScreen();
    } else {
      setError(true);
    }
  }

  const sendEmailToGoogleSheets = () => {
    var formData = new FormData();
    formData.append("email", email);
    fetch(googleSheetsEmailScriptUrl, { method: 'POST', body: formData })
      .then(response => console.log('Success!', response))
      .catch(error => console.error('Error!', error.message))
  }

  
  return (
    <div className="get-started">
      <script src="https://wzrd.in/standalone/formdata-polyfill"></script>
      <script src="https://wzrd.in/standalone/promise-polyfill@latest"></script>
      <script src="https://wzrd.in/standalone/whatwg-fetch@latest"></script>
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            What is your email address?
          </Typography>
          <TextField
            error={error}
            id="standard-error-helper-text"
            label="Your email"
            // defaultValue="david@example.com"
            value={email}
            fullWidth={true}
            variant="outlined"
            helperText={error ? 'Please enter a valid email address' : ''}
            onChange={(e) => {setEmail(e.target.value)}}
          />
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="primary" onClick={() => { decrementScreen() }}>Back</Button>
          <Button variant="contained" color="primary" onClick={() => { handleSubmit()} }>Submit</Button>
        </CardActions>
      </Card>
    </div>
  );
}