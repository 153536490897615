import React from 'react';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { history } from '../routers/AppRouter';
import { pricingPath } from '../helpers/links';
import { Create } from '@material-ui/icons';
import { createMessageObject } from '../helpers/messages';


const CreateMessageButton = ({ dispatch, auth, account, getStarted, entry }) => {


  const handleClick = async (entry) => {

    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      const message = await createMessageObject(entry);
      const formattedMessage = {
        ...message,
        lastSaved: message.lastSaved ? moment(message.lastSaved).format("MMM Do YYYY") : '',
        created: message.created ? moment(message.created).format("MMM Do YYYY") : ''
      }
      if (message) { amplitude.logEvent('Clicked "Subscribe to Create Message Button"', { message: formattedMessage }) };
    }
    history.push(pricingPath);
  }

  return (
    <Button
      variant="contained"
      color="primary"
      style={{ borderRadius: 25 }}
      onClick={() => handleClick(entry)}
      endIcon={<Create />}
    >
      Start writing
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(CreateMessageButton);