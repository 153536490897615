import React, {useState} from 'react';
import IntroCard from './IntroCard';
import EmailCaptureCard from './EmailCaptureCard';
import { emailCaptureFlag } from '../flags/flags';
import { history } from '../routers/AppRouter';
import { viewScenarioPath } from '../helpers/links';
import ScenarioCard from './ScenarioCard';

export default () => {

  const [screen, setScreen] = useState(1);

  const incrementScreen = () => {
    emailCaptureFlag ? setScreen(screen + 1) : viewScenario();

  }

  const decrementScreen = () => {
    setScreen(screen - 1);
  }

  const viewScenario = () => {
    history.push(viewScenarioPath)
  }


  if (screen === 1) {
    return <IntroCard incrementScreen={incrementScreen} />
  } else if (screen === 2) {
    return <EmailCaptureCard incrementScreen={viewScenario} decrementScreen={decrementScreen}/>
  } else if (screen === 3) {
    return <ScenarioCard />
  }

}