const expandedBoxReducerDefaultState = false;

const expandedBoxReducer = (
  state = expandedBoxReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_EXPANDED_BOX':
      return action.box
    default:
      return state;
  }
}

export default expandedBoxReducer;
