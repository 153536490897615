import { history } from '../routers/AppRouter';
import { firebase } from '../firebase/firebase';
import { startUnsetAccount } from '../actions/account';

export const signin = (uid) => ({
  type: 'SIGNIN',
  uid
});

// history.push is async.  I am awaiting it so the original page does not render in the background without sufficient state information.
export const startLogout = () => {
  return (dispatch) => {
    return firebase.auth().signOut().then(async () => {
      await history.push('/');
      // dispatch(unsetInitialState())
    }).catch((e) => {
      console.log('Error: could not sign out user: ', e)
    });
  }
}

export const signout = () => ({
  type: 'SIGNOUT'
});

export const startSignout = () => {
  return (dispatch) => {
    firebase.auth().signOut().then( async () => {
      await dispatch(signout());
      await dispatch(startUnsetAccount())
      await history.push('/');
      console.log('Signed out user');
    }).catch(function (error) {
      console.log('Error: Could not sign out user.');
    });
  }
}

export const signinWithRoles = (uid, roles) => ({
  type: 'SIGNIN_WITH_ROLES',
  uid,
  roles
});

export const startSigninWithRoles = (uid, roles) => {
  return (dispatch) => {
    dispatch(signinWithRoles(uid, roles));
  }
}