import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../routers/AppRouter';
import { chooseTemplatesPath } from '../helpers/links';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const NewMessageButton = ({ dispatch, auth, account }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      amplitude.logEvent('Go to Choose Templates');
    }
    history.push(chooseTemplatesPath());
  }

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      endIcon={<Create />}
      onClick={handleClick}
      style={{ borderRadius: 25, marginLeft: 16, marginRight: 16 }}
    >
      Create message
    </Button>  
  )
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(NewMessageButton); 