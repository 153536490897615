import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography, CardActions } from '@material-ui/core';
import { FlightTakeoff, Apple, Web, LocalHospital, Group, Loyalty, EmojiPeople, CardGiftcard, ImportContacts, AttachMoney, LocalLibrary, TrendingUp, Event } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingPage from './LoadingPage';
import { startInitTemplatesAbout, startUnsetTemplatesAbout } from '../actions/templatesAbout';
import CreateMessageButton from './CreateMessageButton';
import SubscribeToCreateMessageButton from './SubscribeToCreateMessageButton';
import { statusIsTrialorPaid } from '../helpers/auth';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ChooseTemplatesCard = ({dispatch, templatesAbout, match, auth, account}) => {
  
  const classes = useStyles();
  const { getstarted } = match.params;
  const getStarted = getstarted === 'getstarted' ? true : ''

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(startInitTemplatesAbout());
      return setIsLoading(false);
    }
    fetchData();

    const cleanup = () => {
      dispatch(startUnsetTemplatesAbout());
    }
    return cleanup
  }, [dispatch])

  const icons = (icon) => {
    if (icon === 'user-outreach-email') {
      return <Group />
    } else if (icon === 'product-hunt') {
      return <FlightTakeoff />
    } else if (icon === 'app-store') {
      return <Apple />
    } else if (icon === 'landing-page') {
      return <Web />
    } else if (icon === 'pandemic-response') {
      return <LocalHospital />
    } else if (icon === 'product-announcement') {
      // return <Mic />
      return <CardGiftcard/>
    } else if (icon === 'sale-announcement') {
      return <Loyalty />
    } else if (icon === 'EmojiPeople') {
      return <EmojiPeople />
    } else if (icon === 'ImportContacts') {
      return <ImportContacts />
    } else if (icon === 'AttachMoney') {
      return <AttachMoney />
    } else if (icon === 'LocalHospital') {
      return <LocalHospital />
    } else if (icon === 'LocalLibrary') {
      return <LocalLibrary />
    } else if (icon === 'TrendingUp') {
      return <TrendingUp />
    } else if (icon === 'Event') {
      return <Event />
    }   };

  const [expandedBox, setExpandedBox] = useState(false);
  const changeExpansion = (templateId) => {
    if (templateId === false) {
      setExpandedBox(false);
    } else if (expandedBox === templateId) {
      setExpandedBox(false);
    } else {
      setExpandedBox(templateId);
    }
  };
 

  return isLoading ? <LoadingPage/> : (
    <div className="get-started">
      <div className="choose-templates-card">
        
        {/* Title */}
        <div className="choose-templates-card__title">
          <div id="panel1d-header">
            <Typography>What type of message do you want to write?</Typography>
          </div>
        </div>

        {Object.entries(templatesAbout)
        .filter((entry) => {return entry[1].status !== 'invisible'})
        .sort((a, b) => (a[1].order > b[1].order ? 1 : -1))
        .map( (entry) => {
          return <Accordion expanded={expandedBox === entry[0]} onChange={() => {changeExpansion(entry[0])}} key={entry[0]}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className={classes.heading}>{icons(entry[1].icon)}</div>
              <Typography className={classes.secondaryHeading} color="textSecondary">{entry[1].name}</Typography>
            </AccordionSummary>
            <AccordionDetails className="template-card__details">
              <Typography>
                {entry[1].description}
              </Typography>
            </AccordionDetails>
            <CardActions className="template-card__details">
              {statusIsTrialorPaid(auth.roles) ? (
                <CreateMessageButton getStarted={getStarted} entry={entry} />
              ) : (
                <SubscribeToCreateMessageButton getStarted={getStarted} entry={entry} />
              )}
            </CardActions>
          </Accordion>
        })}

      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    templatesAbout: state.templatesAbout,
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(ChooseTemplatesCard);