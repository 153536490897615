import database from '../firebase/firebase';

const initMessages = (messages) => ({
  type: 'INIT_MESSAGES',
  messages
});

export const startInitMessages = (uid) => {
  return (dispatch) => {
    return database.ref(`users/${uid}/messages`).once('value').then(
      async (snapshot) => {
        let messages = snapshot.val();
        if (messages === null || messages === undefined) { messages = false }
        return await dispatch(initMessages(messages));
      }
    ).catch(
      (e) => { console.log('Error retrieving messages from database: ', e) }
    );
  };
};

const unsetMessages = () => ({
  type: 'UNSET_MESSAGES',
});

export const startUnsetMessages = () => {
  return (dispatch) => {
    dispatch(unsetMessages());
  };
};

const saveMessage = (messageId, updates) => ({
  type: 'SAVE_MESSAGE',
  messageId,
  updates
});

export const startSaveMessage = (uid, messageId, updates) => {
  return (dispatch) => {
    return database.ref(`users/${uid}/messages/${messageId}`).update(updates).then(() => {
      return dispatch(saveMessage(messageId, updates))
    }).catch(
      (e) => { console.log('Error saving message: ', e) }
    )
  }
}

const removeMessage = (messageId) => ({
  type: 'REMOVE_MESSAGE',
  messageId
});

export const startRemoveMessage = (messageId, uid) => {
  return (dispatch) => {
    return database.ref(`users/${uid}/messages/${messageId}`).remove().then(() => {
      dispatch(removeMessage(messageId));
    }).catch(
      (e) => { console.log('Error removing message: ', e) }
    );
  }
}