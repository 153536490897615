import React, {useState} from 'react';
import { Typography, Button, CardContent, Card, TextField, Grid, Link, FormLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { messagesPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { validateEmail } from '../helpers/formValidation';
import { formspreeUrl, env } from '../private/private';

const ContactPage = ({auth, account}) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [formSent, setFormSent] = useState(false);


  const handleSubmit = (e) => {
    let errors = false;
    if (firstName.length === 0) {
      setFirstNameError(true);
    }
    if (lastName.length === 0) {
      setLastNameError(true);
    }
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (message.length === 0) {
      setMessageError(true);
    }
    if (!errors) {
      submit(e);
    }
  }

  const submit = (e) => {
    e.preventDefault();
    const data = {firstName, lastName, email, message, account, env}
    const xhr = new XMLHttpRequest();
    xhr.open('post', formspreeUrl);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setFormSent(true);
      } else {
        setSubmissionError(`There was a problem.  Your message could not be sent`);
      }
    };
    xhr.send(JSON.stringify(data));
  }

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Contact us
          </Typography>
          {formSent ?
          (
            <div>
                <Typography gutterBottom>Your message was received.  We'll respond promptly.</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button variant="contained"
                      color="primary"
                      onClick={() => { history.push(messagesPath) }}
                      fullWidth
                    >
                      Back to your messages
                    </Button>
                  </Grid>
                </Grid>
            </div>
          ) : 
          (  <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={firstName}
                  label="First name"
                  onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false) }}
                  variant="outlined"
                  required
                  fullWidth
                  helperText={firstNameError ? 'Field cannot be empty' : ''}
                  error={!!firstNameError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={lastName}
                  label="Last name"
                  onChange={(e) => { setLastName(e.target.value); setLastNameError(false) }}
                  variant="outlined"
                  required
                  fullWidth
                  helperText={lastNameError ? 'Field cannot be empty' : ''}
                  error={!!lastNameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  label="Email"
                  onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                  variant="outlined"
                  required
                  fullWidth
                  helperText={emailError ? 'Please enter a valid email address' : ''}
                  error={!!emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={message}
                  label="Message"
                  onChange={(e) => { setMessage(e.target.value); setMessageError(false) }}
                  variant="outlined"
                  required
                  fullWidth
                  helperText={messageError ? 'Please enter a valid message' : ''}
                  error={!!messageError}
                  multiline
                  rows={8}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained"
                  color="primary"
                  onClick={(e) => { handleSubmit(e) }}
                  fullWidth>
                    Send
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormLabel value={submissionError} error>{submissionError}</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {auth.uid ? 
                    <Link href={messagesPath} variant="body2">
                      Back to your messages
                    </Link> : ''
                    // <Link onClick={goBack} variant="body2">
                    //   Back to previous page
                    // </Link>
                  }
                </div>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(ContactPage);