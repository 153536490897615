const activeCategoriesReducerDefaultState = {};

const activeCategoriesReducer = (
  state = activeCategoriesReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_ACTIVE_CATEGORIES':
      return action.activeCategories
    default:
      return state;
  }
}

export default activeCategoriesReducer;
