const trialActivityDefaultState = {
  messagesCreated: false
}

const trialActivityReducer = (
  state = trialActivityDefaultState, action
) => {
  switch (action.type) {
    case 'INIT_TRIAL_ACTIVITY':
      return {
        messagesCreated: action.trialActivity.messagesCreated ? action.trialActivity.messagesCreated : 0
      }
    case 'UNSET_TRIAL_ACTIVITY':
      return trialActivityDefaultState;
    default:
      return state;
  }
}

export default trialActivityReducer;
