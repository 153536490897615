const saveSnackbarReducerDefaultState = false;

const saveSnackbarReducer = (
  state = saveSnackbarReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_SAVE_SNACKBAR':
      return action.open
    default:
      return state;
  }
}

export default saveSnackbarReducer;
