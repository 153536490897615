import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, CardContent, Card, TextField, Link, FormLabel, CircularProgress } from '@material-ui/core';
import { validateEmail, isPasswordError } from '../../helpers/formValidation';
import { signinPath, termsOfUseUrl, privacyPolicyUrl } from '../../helpers/links';
import { firebase } from '../../firebase/firebase';
import { connect } from 'react-redux';
import { startSaveAccount } from '../../actions/account';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';
import { primary } from '../../styles/styles.scss';
import { formspreeUrl, env } from '../../private/private';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));


const SignupPage = ({dispatch}) => {
  const classes = useStyles();


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);


  const handleSubmit = () => {
    let errors = false;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (isPasswordError(password)) {
      setPasswordError(isPasswordError(password));
      errors = true;
    }
    if (firstName.length === 0) {
      setFirstNameError(true);
    }
    if (lastName.length === 0) {
      setLastNameError(true);
    }
    if (!errors) {
      submit();
    }
  }

  const submit = async () => {

    setButtonLoading(true);

    await firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(async (auth) => {
      const account = {
        uid: auth.user.uid,
        firstName,
        lastName,
        email
      }
      if (amplitudeFlag) {
        if (auth.user.uid) { amplitude.setUserId(auth.user.uid) };
        amplitude.logEvent('Sign up');
      }
      await dispatch(startSaveAccount(account));

      const data = { message: 'A new user has signed up for SplashPad.', uid: auth.user.uid, firstName, lastName, email, env }
      const xhr = new XMLHttpRequest();
      xhr.open('post', formspreeUrl);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          // console.log('Form was sent');
        } else {
          // console.log(`There was a problem.  The message could not be sent`);
        }
      };
      xhr.send(JSON.stringify(data));

    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode) {
        setSubmissionError(errorMessage);
        console.log('errorCode: ', errorCode, 'error: ', errorMessage);
      }
      setButtonLoading(false);
    });


  }

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Sign up
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={firstName}
                label="First name"
                onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={firstNameError ? 'Field cannot be empty' : ''}
                error={!!firstNameError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={lastName}
                label="Last name"
                onChange={(e) => { setLastName(e.target.value); setLastNameError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={lastNameError ? 'Field cannot be empty' : ''}
                error={!!lastNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                label="Email"
                onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={emailError ? 'Please enter a valid email address' : ''}
                error={!!emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                label="Password"
                onChange={(e) => { setPassword(e.target.value); setPasswordError(false) }}
                variant="outlined"
                type="password"
                autoComplete="current-password"
                // required
                fullWidth
                helperText={passwordError ? passwordError : 'Use 10 or more characters.'}
                error={!!passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="signup-page__terms-text">
                <Typography variant="body2">By clicking "Sign up", you agree to the <Link href={termsOfUseUrl} target="_blank" rel="noopener">Terms of Use</Link> and <Link href={privacyPolicyUrl} target="_blank" rel="noopener">Privacy Policy</Link>.</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {handleSubmit() }}
                  fullWidth
                  disabled={buttonLoading}
                >
                  Sign up
                </Button>
                {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel value={submissionError} error>{submissionError}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="body2">Already have an account? <Link href={signinPath} variant="body2">Sign in</Link></Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {

  }
}

export default connect(mapStateToProps)(SignupPage);