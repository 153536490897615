import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography, Container, Link, CircularProgress} from '@material-ui/core';
// import StarIcon from '@material-ui/icons/StarBorder';
import { makeStyles } from '@material-ui/core/styles';
import { contactPath, accountPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { firebase } from '../firebase/firebase';
import { amplitude } from '../helpers/amplitude';
import { stripePromise } from '../private/private';
import { primary } from '../styles/styles.scss';
import { connect } from 'react-redux';
import { subscriptionStatusIs, statusIsTrialorPaid, trialStatusIs } from '../helpers/auth';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      // listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  discount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: theme.spacing(2),
  },
  formerPrice: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    // marginBottom: theme.spacing(2),
  },
  listContainer: {
    marginLeft: '24px',
    marginRight: '24px',
  },
  noDiscount: {
    visibility: 'hidden'
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  wrapperContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  refundLine: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
}));

const daysLeft = () => {
  var d = new Date();
  return 9 - d.getDay();
}


const PricingPage = ({auth, account}) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCheckout = async (event, tierName) => {

    setButtonLoading(tierName);

    // Fetch checkout session
    let sessionId;
    try {
      const fetchCheckoutSession = firebase.functions().httpsCallable('fetchCheckoutSession');
      const session = await fetchCheckoutSession({ email: account.email, uid: auth.uid });
      sessionId = session.data.id;
    } catch (error) {
      setError(true);
      amplitude.logEvent('Unable to connect to fetch checkout session', { error });
    }

    // Redirect to Checkout
    if (sessionId) {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        setError(true);
        amplitude.logEvent('Unable to redirect to checkout', { error });
      };
    }

    setButtonLoading(false);
  };








  return (
    <div>
      {/* Hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h3" variant="h3" align="center" color="textPrimary">
            Subscribe to SplashPad
          </Typography>

          {
            subscriptionStatusIs(auth.roles, 'active') ? (
              <Typography variant="h6" align="center" color="textSecondary" component="p">
                You are subscribed.
              </Typography>
            ) : (
              trialStatusIs(auth.roles, 'active') ? (
                <Typography variant="h6" align="center" color="textSecondary" component="p">
                  You're using the free trial.
                </Typography>
              ) : (
                <Typography variant="h6" align="center" color="textSecondary" component="p">
                  Your trial expired.
                </Typography>
              )
            )
          }
        </Container>

      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" justify="center">
          <Grid item xs={12} sm={5} md={5}>
            <Card>
              <CardHeader
                title="Starter Plan"
                subheader=' '
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h4" color="textPrimary">
                    $19.99
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /mo
                  </Typography>
                </div>
                {/* <div className={classes.refundLine}>
                  <Typography component="body2" color="textSecondary" align="center">
                    If you're unhappy, cancel within 30 days for a refund.
                  </Typography>                
                </div> */}
                <div className={classes.listContainer}>
                  <ul className="pricing__list-checkmarks">
                    <Typography component="li" variant="subtitle1">
                      Over 200 high-performing language suggestions
                    </Typography>
                    <Typography component="li" variant="subtitle1">
                      10 premium pre-written messages
                    </Typography>
                    <Typography component="li" variant="subtitle1">
                      Priority email support
                    </Typography>
                    <Typography component="li" variant="subtitle1">
                      Cancel at any time
                    </Typography>
                  </ul>
                </div>
              </CardContent>
              <CardActions>
                <div className={classes.wrapperContainer}>
                  {!subscriptionStatusIs(auth.roles, 'active') ? (
                    <div className={classes.wrapper}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={(e) => { handleCheckout(e, 'SplashPad Starter Plan') }}
                        color="primary"
                        disabled={buttonLoading === "SplashPad Starter Plan"}
                      >
                        Subscribe Now
                      </Button>
                      {buttonLoading === "SplashPad Starter Plan" && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  ) : (
                    <div className={classes.wrapper}>
                      <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => {history.push(accountPath)}}
                      >
                          Update Your Subscription
                      </Button>
                    </div>

                  )}

                </div>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {error ? (
          <div className="pricing__contact-line">
            <Typography variant="body2" color="secondary">
              Unable to open to checkout page.  If this persists, please <Link href={contactPath}>Contact us</Link>.
            </Typography>
          </div>
        ) : (
            <div className="pricing__contact-line"><Typography><span>Have questions?</span>  <span className="pricing__contact-space"><Link href={contactPath}>Contact us</Link></span>.</Typography></div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(PricingPage);