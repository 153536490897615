import React from 'react';
import { Typography, Button, CardActions, CardContent, Card } from '@material-ui/core';

export default ({ incrementScreen }) => {

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Learn SplashPad
          </Typography>
          <Typography color="textSecondary" component="p" className="" gutterBottom={true}>
            In this 2-minute tutorial, we’ll show you how to write a <b>high performing</b> email that sells your product.
          </Typography>
        </CardContent>
        <CardActions>
          <Button style={{ borderRadius: 25 }} variant="contained" color="primary" onClick={() => { incrementScreen() }}>Continue</Button>
        </CardActions>
      </Card>
    </div>
  );
}