import database from '../firebase/firebase';
import { retrieveTemplateContent } from '../helpers/templatesContent';
import { v4 as uuid } from 'uuid';


export const retrieveMessage = async (uid, messageId) => {
  return await database.ref(`users/${uid}/messages/${messageId}`).once('value').then(
    async (snapshot) => {
      if (!snapshot.hasChildren()) {throw new Error('This message does not exist or the user lacks permission to access it.')}
      const temp = snapshot.val();
      return await temp === null || temp === undefined ? { subject: '', body: '' } : await temp;
    }
  ).catch(
    (e) => {
      console.log('Error retrieving message from database: ', e);
      throw new Error('Error retrieving message from database.')
    }
  );
}

export const createMessageObject = async (entry) => {
  const messageId = uuid()
  const lastSaved = Date.now();
  const created = lastSaved;
  const title = '';
  const templateId = entry[0];
  const { subject, body } = await retrieveTemplateContent(templateId);
  const message = {
    messageId,
    lastSaved,
    created,
    title,
    templateId,
    subject,
    body
  }
  return message;
}