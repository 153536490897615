import React from 'react';
import { connect } from 'react-redux';
import TemplateRow from './TemplateRow';


const TemplatesGrid = ({ templatesAbout }) => {

  return (
    <div>
      {Object.entries(templatesAbout).map((entry) => {
        return (
          <TemplateRow entry={entry} key={entry[0]} />
        )
      })}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    templatesAbout: state.templatesAbout,
  }
}

export default connect(mapStateToProps)(TemplatesGrid);