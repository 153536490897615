import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { history } from '../routers/AppRouter';
import { deleteMessagePath } from '../helpers/links';

const DeleteMessageButton = ({messageId, blank}) => {

  const handleClick = () => {
    history.push(deleteMessagePath(messageId));
  }

  return !blank ? (
    <Tooltip title="Delete Message"><IconButton onClick={handleClick}><Delete /></IconButton></Tooltip>
  ) : (
    <div className="open-message__blank"></div>
  )

}

export default DeleteMessageButton; 