import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { termsOfUseUrl, privacyPolicyUrl } from '../../helpers/links';



const LegalContent = ({dispatch, auth, account}) => {
  
  return (
    <div>
          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12}>
              <Typography>
                <Link href={termsOfUseUrl}>Terms of Use</Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Link href={privacyPolicyUrl}>Privacy Policy</Link>
              </Typography>
            </Grid>
          </Grid>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(LegalContent);