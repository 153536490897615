import { returnSuggestionsArray } from '../helpers/categories';
import { startSetExpandedBox } from '../actions/expandedBox';
import { startSetActiveSuggestion } from '../actions/activeSuggestion';
import { startSetHighlightMode } from '../actions/highlightMode';

export const showHighlightButtons = async (message, categories, dispatch) => {
  if (message.body.length === 0) {
    console.log(`Can't show highlight buttons because the body is empty.`)
  } else if (getMarks(categories).length === 0) {
    console.log(`Can't show highlight buttons because no terms are highlighted.`)
  } else {
    elevateHighlightPanel();
    const marksArray = getMarks(categories);
    addOnClicksToMarks(marksArray, dispatch);
    setMarksButtonStyle(marksArray)
  }
}

export const hideHighlightButtons = (categories, dispatch) => {
  lowerHighlightPanel();
  const marksArray = getMarks(categories);
  removeOnClicksFromMarks(marksArray, dispatch);
  removeMarksButtonStyle(marksArray)
}

export const elevateHighlightPanel = () => {
  const highlights = document.getElementsByClassName('hwt-backdrop');
  const subjectHighlights = highlights[0];
  subjectHighlights.className += " hwt-backdrop__custom-overlay-add";
  const bodyHighlights = highlights[1];
  bodyHighlights.className += " hwt-backdrop__custom-overlay-add";
}

export const lowerHighlightPanel = () => {
  try {
    const highlights = document.getElementsByClassName('hwt-backdrop');
    const subjectHighlights = highlights[0];
    subjectHighlights.className = " hwt-backdrop";
    const bodyHighlights = highlights[1];
    bodyHighlights.className = " hwt-backdrop";
  } catch {
    console.log('Cannot modify subject class');
  }

}

export const getMarks = (categories) => {
  if (categories) {
    const suggestionsArray = returnSuggestionsArray(categories);
    let marksArray = [];
    suggestionsArray.forEach((suggestion) => {
      let elems = document.querySelectorAll(`mark[id='${suggestion[2]}']`)
      if (elems !== null && elems !== undefined && elems.length > 0) {
        elems.forEach((elem) => {
          marksArray.push({ category: suggestion[0], mark: elem });
        })
      }
    });
    return marksArray;
  } else {
    let marksArray = [];
    let elems = document.querySelectorAll(`mark`)
    if (elems !== null && elems !== undefined && elems.length > 0) {
      elems.forEach((elem) => {
        marksArray.push({ category: false, mark: elem });
      })
    }
    return marksArray;
  }

}

export const addOnClicksToMarks = (marksArray, dispatch) => {
  if (marksArray) {
    marksArray.forEach((elem) => {
      elem.mark.addEventListener("click", handleClick(elem, dispatch));
    })
  }
}

export const removeOnClicksFromMarks = (marksArray, dispatch) => {
  if (marksArray) {
    marksArray.forEach((elem) => {
      elem.mark.removeEventListener("click", handleClick(elem, dispatch));
    })
  }
}

const handleClick = (elem, dispatch) => {
  return (event) => {

    // 1. Open the suggestion in the TechniqueList
    const index = getIndex(event.target);
    const innerTextLength = event.target.innerText.length;
    const ancestor = event.target.getAttribute('ancestor');
    const categoryId = elem.category;
    const activeSuggestion = { suggestionId: elem.mark.id, index, innerTextLength, ancestor, text: event.target.innerText }
    dispatch(startSetExpandedBox(categoryId));
    dispatch(startSetActiveSuggestion(activeSuggestion));

    // 2. Hide highlight buttons and turn highlightMode to 'off';
    hideHighlightButtons(false, dispatch);
    dispatch(startSetHighlightMode('off'));

    // 3. Hide all marks other than the one that was clicked.
    event.target.setAttribute('selectedelement', true);
    hideAllSugsExceptSelected();

    // 4. Scroll the new TechniqueList into view
    document.getElementById(elem.category).scrollIntoView(true);
  }
}

const setMarksButtonStyle = (marksArray) => {
  marksArray.forEach((item) => {
    item.mark.className += " hwt__custom-button";
  })
}

const removeMarksButtonStyle = (marksArray) => {
  marksArray.forEach((item) => {
    item.mark.classList.remove("hwt__custom-button");
  });
}

export const getIndex = (elem) => {
  let index = 0;
  let previousSiblingExists = elem.previousSibling ? true : false;
  while (previousSiblingExists) {
    if (elem.previousSibling && elem.previousSibling.nodeType === 3) {
      index += elem.previousSibling.length;
    } else if (elem.previousSibling && elem.previousSibling.nodeType === 1) {
      index += elem.previousSibling.innerText.length
    } else {
      // Error: Could not extract text;
    }
    elem = elem.previousSibling;
    if (!elem || elem === null) { previousSiblingExists = false };
  }
  return index;
}

const hideAllSugsExceptSelected = () => {
  const elems = document.querySelectorAll(`mark`);
  elems.forEach((elem) => {
    if (!elem.getAttribute('selectedelement')) {
      let newElem = elem.cloneNode(true);
      newElem.setAttribute('class', 'hwt__disabled-mark');
      elem.parentNode.replaceChild(newElem, elem);
    }
  })  
}