import React, {useState} from 'react';
import {InputLabel, MenuItem, Button, FormControl, Select, FormLabel, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import { connect } from 'react-redux';
import { startUpdateTemplateContentCategories } from '../../actions/templatesContent';
import { returnNumSuggestionsUsed } from '../../helpers/categories';

const TemplateCategories = ({dispatch, templatesAbout, templatesContent, categories}) => {
  
  const [templateId, setTemplateId] = useState('')
  const [selectedCategories, setSelectedCategories] = useState({});
  
  const handleChange = (e) => {
    const tempId = e.target.value;
    setTemplateId(tempId);
    if (templatesAbout && templatesContent && templatesContent[tempId] && templatesContent[tempId].selectedCategories) {
      setSelectedCategories(templatesContent[tempId].selectedCategories);
    }
  }

  const handleCheck = (categoryId) => {
    console.log('categoryId:', categoryId);
    if (!Object.keys(selectedCategories).includes(categoryId)) {
      console.log('2:');
      let newSelectedCategories = {
        ...selectedCategories,
        [categoryId]: true
      }
      console.log('newSelectedCategories: ', newSelectedCategories);
      setSelectedCategories(newSelectedCategories)
    } else {
      let newSelectedCategories = {...selectedCategories}
      delete newSelectedCategories[categoryId];
      setSelectedCategories(newSelectedCategories);
    }
  }

  const handleSave = () => {
    dispatch(startUpdateTemplateContentCategories(templateId, selectedCategories))
  }

  return (
    <div>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Template</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={templateId}
          onChange={handleChange}
        >
          {Object.entries(templatesAbout).map((template) => {
            if (template[1].name) {
              return <MenuItem value={template[0]} key={template[0]}>{template[1].name}</MenuItem>
            } else {
              return <div>Cannot retrieve template name</div>
            }
          })}
        </Select>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Assign categories</FormLabel>
        <FormLabel component="legend">Selected categories: {!selectedCategories ? 0 : Object.keys(selectedCategories).length}</FormLabel>
        <FormLabel component="legend">Num suggestions used: {!selectedCategories || !templatesContent ? 0 : returnNumSuggestionsUsed(categories, templatesContent)}</FormLabel>
        <FormGroup>
          {Object.entries(categories)
          .sort((a, b) => (a[1].categoryName > b[1].categoryName ? 1 : -1))
          .map((category) => {
            return (
              <FormControlLabel
                control={<Checkbox checked={Object.keys(selectedCategories).includes(category[0])} onChange={() => handleCheck(category[0])} name={category[1].categoryName} />}
                label={category[1].categoryName}
                key={category[0]}
              />
            )
          })}
        </FormGroup>
      </FormControl>

      <Button onClick={handleSave} variant="contained" color="primary">Save selected categories</Button>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories,
    templatesContent: state.templatesContent,
    templatesAbout: state.templatesAbout
  }
}

export default connect(mapStateToProps)(TemplateCategories);