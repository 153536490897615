import database from '../firebase/firebase';

export const retrieveTemplateAbout = async (templateId) => {
  return await database.ref(`templatesAbout/${templateId}`).once('value').then(
    async (snapshot) => {
      const temp = snapshot.val();
      return await temp === null || temp === undefined ? { } : await temp;
    }
  ).catch(
    (e) => { console.log('Error retrieving templateAbout from database: ', e) }
  );
}