import React from 'react';
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import { startSetHighlightMode } from '../actions/highlightMode';
import { showHighlightButtons } from '../helpers/highlightButtons';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import { withStyles } from '@material-ui/core/styles';
import { secondaryBackgroundSwitch } from '../styles/styles.scss';
import { startUnsetActiveSuggestion } from '../actions/activeSuggestion';
import { highlightActiveSuggestions, initJqueryHighlightPluginToNone } from '../helpers/highlightText';
import { startSetMessageLastUpdated } from '../actions/messageLastUpdated';


const ColoredSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: secondaryBackgroundSwitch,
    },
    '&$checked + $track': {
      backgroundColor: secondaryBackgroundSwitch,
    },
  },
  checked: {},
  track: {},
})(Switch);

const AlternativesSwitch = ({dispatch, activeCategories, highlightMode, message, auth, account, activeSuggestion, messageLastUpdated}) => {

  const toggleSwitch = () => {
    if (highlightMode === 'alternatives') {
      handleChange('off');
    } else {
      handleChange('alternatives');
    }
  }

  const handleChange = async (newMode) => {

    // 1. Log amplitude event.
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (newMode && highlightMode) { amplitude.logEvent('Change Highlight Mode', { oldMode: highlightMode, newMode: newMode }) };
    }

    // 2. If the new mode and old mode are the same, do nothing.
    if (newMode === 'off' && highlightMode === 'off') {
      console.log(`Already in ${highlightMode} mode.  Nothing to do.`)
    } else if (newMode === 'alternatives' && highlightMode === 'alternatives') {
      console.log(`Already in ${highlightMode} mode.  Nothing to do.`);

    // 3. If turning alternatives off, destroy the highlights (which destroys the buttons) and change the highlight mode to adjust color.
    } else if (newMode === 'off' && highlightMode === 'alternatives') {
      if (activeSuggestion) {dispatch(startUnsetActiveSuggestion())}
      initJqueryHighlightPluginToNone();
      dispatch(startSetHighlightMode('off'));

    // 4. If turning alternatives on, highlight active suggestions, show the buttons, and change the mode.
    } else if (newMode === 'alternatives' && highlightMode === 'off') {
      highlightActiveSuggestions(activeCategories);
      showHighlightButtons(message, activeCategories, dispatch);
      dispatch(startSetHighlightMode('alternatives'));
      if (messageLastUpdated) { dispatch(startSetMessageLastUpdated(false)) };
    }

  };

  return (
    <div id="alternatives-switch">
      <FormGroup row>
        <FormControlLabel
          control={
            <ColoredSwitch
              checked={highlightMode === 'alternatives'}
              onChange={toggleSwitch}
              name="checkedA"
            />
          }
          label="Customize"
        />
      </FormGroup>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    highlightMode: state.highlightMode,
    message: state.message.present,
    auth: state.auth,
    account: state.account,
    activeCategories: state.activeCategories,
    activeSuggestion: state.activeSuggestion,
    messageLastUpdated: state.messageLastUpdated
  }
}

export default connect(mapStateToProps)(AlternativesSwitch);