import React, { useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { Save, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import {startSaveSuggestion, startRemoveSuggestion} from '../../actions/categories';
import { replaceDumbText } from '../../helpers/editText';

const SuggestionRow = ({ dispatch, entry }) => {

  const categoryId = entry[0];
  const categoryName = entry[1];
  const suggestionId = entry[2];
  const [suggestionName, setSuggestionName] = useState(entry[3])
  const [note, setNote] = useState(entry[4])

  const handleSave = () => {
    dispatch(startSaveSuggestion(categoryId, suggestionId, { suggestionName, note }));
  }

  const handleDelete = () => {
    dispatch(startRemoveSuggestion(categoryId, suggestionId));
  }


  return (
    <div className="admin-suggestion-row">
      <div className="admin-text-small">
        <TextField id={entry[0]} label='catId' value={categoryId} fullWidth={true} />
      </div>
      <div className="admin-text-medium">
        <TextField id={entry[1]} label='categoryName' value={categoryName} fullWidth={true} />
      </div>

      <div className="admin-text-small">
        <TextField id={entry[2]} label='sugId' value={suggestionId} fullWidth={true} />
      </div>
      <div className="admin-text-large">
        <TextField id={entry[3]} label='suggestionName' value={suggestionName} fullWidth={true} onChange={(e) => { setSuggestionName(replaceDumbText(e.target.value)) }} />
      </div>
      <div className="admin-text-large">
        <TextField id={entry[4]} label='note' value={note} fullWidth={true} onChange={(e) => { setNote(replaceDumbText(e.target.value)) }} />
      </div>
      <div>
        <IconButton id='save-suggestion' variant="contained" onClick={() => { handleSave() }}><Save /></IconButton>
      </div>
      <div>
        <IconButton id='delete-suggestion' variant="contained" onClick={() => { handleDelete() }}><Delete /></IconButton>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(SuggestionRow);




