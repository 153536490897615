import React, { useState } from 'react';
import {TextField, Button} from '@material-ui/core';
import { firebase } from '../../firebase/firebase';

const SetStarterRole = () => {

  const [email, setEmail] = useState('');

  const handleClick = async (set) => {
    const setStarterRole = firebase.functions().httpsCallable('setStarterRole');
    await setStarterRole({email, set}).then(() => {
      console.log(`${email} has been made given starter plan privileges.`);
    }).catch((error) => {
      console.log('Error: ', error);
    })
  }

  return ( 
    <div className="set-roles">
      <TextField
        value={email}
        onChange={(e) => {setEmail(e.target.value)}}
        variant="outlined"
        label="Email"
      />
      <Button variant="contained" color="primary" onClick={() => { handleClick(true) }}>Set Starter Role</Button>
      <Button variant="contained" color="primary" onClick={() => { handleClick(false) }}>Unset Starter Role</Button>
    </div>
  )
}

export default SetStarterRole;