const copySnackbarReducerDefaultState = false;

const copySnackbarReducer = (
  state = copySnackbarReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_COPY_SNACKBAR':
      return action.open
    default:
      return state;
  }
}

export default copySnackbarReducer;
