import undoable from 'redux-undo';

const messageReducerDefaultState = {
  subject: '',
  body: '',
  templateId: '',
  time: false
}

const messageReducer = (
  state = messageReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_MESSAGE_SUBJECT':
      return {
        ...state,
        subject: action.subject,
        time: Date.now()
      }
    case 'SET_MESSAGE_BODY':
      return {
        ...state,
        body: action.body,
        time: Date.now()
      }
    case 'SET_MESSAGE_CONTENT':
      return {
        ...state,
        ...action.message,
        time: Date.now()
      }
    default:
      return state;
  }
}

const undoableMessageReducer = undoable(messageReducer, {
  limit: 25,
})

export default undoableMessageReducer;
