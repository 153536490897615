import React, {useState} from 'react';
import { CircularProgress, Grid, Typography, Button, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { firebase } from '../../firebase/firebase';
import { contactPath, pricingPath } from '../../helpers/links';
import { makeStyles } from '@material-ui/core/styles';
import { primary } from '../../styles/styles.scss';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';
import { subscriptionStatusIs, trialStatusIs } from '../../helpers/auth';


const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  wrapper: {
    position: 'relative',
    width: '100%'
  },
  buttonProgress: {
    color: primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


const BillingContent = ({dispatch, auth, account}) => {
  
  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  


  const openBillingPortal = async () => {
    
    setButtonLoading(true);

    // Fetch billing portal url and redirect to it
    try {
      const openBillingPortal = firebase.functions().httpsCallable('openBillingPortal');
      const {data} = await openBillingPortal();
      window.location.replace(data);
      if (amplitudeFlag) {
        if (auth.uid) { amplitude.setUserId(auth.uid) };
        amplitude.logEvent('Opened billing portal');
      }
    } catch (error) {
      console.log('Error: ', error);
      setError(true);
    }
    setButtonLoading(false);
  }

  const returnSubscriptionStatus = () => {
    if (subscriptionStatusIs(auth.roles, 'active')) {
      return 'You are subscribed to the Starter Plan.'
    } else if ( trialStatusIs(auth.roles, 'active')) {
      return 'You are using the free trial.'
    } else {
      return 'Your trial has expired.'
    }
  }

  return (
    <div>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography component="div">
                {returnSubscriptionStatus()}
              </Typography>
            </Grid>
            {!subscriptionStatusIs(auth.roles, 'active') && !subscriptionStatusIs(auth.roles, 'cancelled') ? '' :
              <Grid item xs={12}>
                <div className={classes.wrapperContainer}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={() => { openBillingPortal() }}
                      disabled={buttonLoading}
                    >
                      Billing portal
                    </Button>
                    {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </div>
              </Grid>
            }
            {!subscriptionStatusIs(auth.roles, 'active') && !subscriptionStatusIs(auth.roles, 'cancelled') ? '' :
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  Use the billing portal to:
                  <ul>
                    <li>View invoices,</li>
                    <li>Update your payment method,</li>
                    <li>Cancel your subscription.</li>
                  </ul>
                </Typography>
              </Grid>
            }
            {subscriptionStatusIs(auth.roles, 'active') && !subscriptionStatusIs(auth.roles, 'cancelled') ? '' :
              <Grid item xs={12}>
                  <Typography>
                    <Link href={pricingPath}>Purchase SplashPad here!</Link>
                  </Typography>
              </Grid>
            }
            {error && <Grid item xs={12}>
              <Typography variant="body2" color="secondary">
                We're sorry.  Something went wrong.  If the problem persists, please <Link href={contactPath}>contact us</Link>.
              </Typography>
            </Grid>
            }
          </Grid>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(BillingContent);