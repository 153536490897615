import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Subject from './Subject';
import Body from './Body';
import {secondaryBackground} from '../styles/styles.scss';
import { connect } from 'react-redux';


const useStyles = makeStyles({
  highlightButtons: {
    backgroundColor: secondaryBackground
  },
});

const Notepad = ({highlightMode}) => {
  const classes = useStyles();

  const paperStyle = highlightMode === 'alternatives' ? `notepad__paper ${classes.highlightButtons}` : `notepad__paper`;

  return (
    <div className="notepad" id="notepad">
      <div className={paperStyle} elevation={3} id="notepad-paper">
        <div className="notepad__subject-bar">
          <div className="notepad__subject-bar-subject">
            <Subject />
          </div>
        </div>
        <Body />
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    highlightMode: state.highlightMode
  }
}

export default connect(mapStateToProps)(Notepad);
