// import SetSuperAdmin from '../components/Admin/SetSuperAdmin';
// import UnderConstruction from '../components/UnderConstruction';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import PrivateRoute from './PrivateRoute';
import NavRoute from './NavRoute';
import PrivateNavRoute from './PrivateNavRoute';
import SignupRoute from './SignupRoute';
import Workspace from '../components/Workspace';
import GetStarted from '../components/GetStarted';
import PricingPage from '../components/PricingPage';
import ChooseTemplatesCard from '../components/ChooseTemplatesCard';
import MessagesPage from '../components/MessagesPage';
import DeleteMessagePage from '../components/DeleteMessagePage';
import ContactPage from '../components/ContactPage';
import Fallback from '../components/Fallback';
import PlanNotAvailable from '../components/PlanNotAvailable';
import SetAdmin from '../components/Admin/SetAdmin';
import ScenarioCard from '../components/ScenarioCard';
import AdminPage from '../components/Admin/AdminPage';
import SignupPage from '../components/Account/SignupPage';
import SigninPage from '../components/Account/SigninPage';
import ForgotPasswordPage from '../components/Account/ForgotPasswordPage';
import AccountPageContainer from '../components/Account/AccountPageContainer';
import UpdatePassword from '../components/Account/UpdatePassword';
import UpdateEmail from '../components/Account/UpdateEmail';
import BuyFailure from '../components/BuyFailure';
import RetrieveProvidedData from '../components/Admin/RetrieveProvidedData';
import SaveProvidedData from '../components/Admin/SaveProvidedData';
import SetStarterRole from '../components/Admin/SetStarterRole';
import Welcome from '../components/Welcome';

export const history = createBrowserHistory();

// const starter = { 'plans': { 'starter': { 'status': 'active' } } };
// const trial = { 'plans': { 'trial': { 'status': 'active' } } };
const admin = { 'admin': true };
const superAdmin = { 'superAdmin': true };

class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <Switch>


            {/* Public site */}
            <NavRoute path="/signin" component={SigninPage} exact={false} />
            <SignupRoute path="/signup" component={SignupPage} exact={true} />
            <NavRoute path="/forgotpassword" component={ForgotPasswordPage} exact={true} />
            <NavRoute path="/getstarted" component={GetStarted} exact={true} />
            <NavRoute path="/getstarted/viewscenario" component={ScenarioCard} exact={true} />
            <Route
              path="/:getstarted/workspace/:templateId"
              component={Workspace}
              exact={true}
              noFooter={true}
            />


            {/* Authenticated users only */}
            <PrivateNavRoute path="/account" component={AccountPageContainer} exact={true} />
            <PrivateNavRoute path="/updatepassword" component={UpdatePassword} exact={true} />
            <PrivateNavRoute path="/updateemail" component={UpdateEmail} exact={true} />
            <PrivateNavRoute path="/pricing" component={PricingPage} exact={true} />
            <PrivateNavRoute path="/enterprise" component={PlanNotAvailable} exact={true} />
            <PrivateNavRoute path="/buyfailure" component={BuyFailure} exact={true} />
            <PrivateNavRoute path="/contact" component={ContactPage} exact={true} />
            <PrivateNavRoute path="/welcome" component={Welcome} exact={true} />
            <PrivateNavRoute path="/" component={MessagesPage} exact={true}/>
            <PrivateNavRoute path="/messages" component={MessagesPage} exact={true} />
            <PrivateNavRoute path="/choosetemplate" component={ChooseTemplatesCard} exact={true}/>
            <PrivateNavRoute path="/deletemessage/:messageId" component={DeleteMessagePage} exact={true}/>
            <PrivateRoute
              path="/workspace/:messageId"
              component={Workspace}
              exact={true}
              noFooter={true} 
            />


            {/* Admin only */}
            <PrivateNavRoute path="/admin" component={AdminPage} exact={true} roleMustBe={[admin]} />
            <PrivateNavRoute path="/admin/setadmin" component={SetAdmin} exact={true} roleMustBe={[superAdmin]} />
            <PrivateRoute
              path="/workspace/"
              component={Workspace}
              exact={true}
              roleMustBe={[{ 'admin': true }]} 
              noFooter={true} 
            />
            <PrivateNavRoute path="/admin/retrieveprovideddata" component={RetrieveProvidedData} exact={true} roleMustBe={[admin]} />
            <PrivateNavRoute path="/admin/saveprovideddata" component={SaveProvidedData} exact={true} roleMustBe={[admin]} />
            <PrivateNavRoute path="/admin/setstarterrole" component={SetStarterRole} exact={true} roleMustBe={[admin]} />
            {/* <PrivateNavRoute path="/admin/setsuperadmin" component={SetSuperAdmin} exact={true} roleMustBe={['superAdmin']}/> */}

            {/* Fallbacks */}
            <Route component={Fallback} exact={true} />
            <Route path="/fallback" component={Fallback} exact={true} />
            {/* <Route path="/underconstruction" component={UnderConstruction} exact={true} /> */}


          </Switch>
        </div>
      </Router>
    )
  }
}

export default AppRouter;