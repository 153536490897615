import database from '../firebase/firebase';

const initCategories = (categories) => ({
  type: 'INIT_CATEGORIES',
  categories
});

export const startInitCategories = () => {
  return (dispatch) => {
    return database.ref(`categories`).once('value').then(
      async (snapshot) => {
        let categories = snapshot.val();
        if (categories === null || categories === undefined) {categories = {}}
        await dispatch(initCategories(categories));
      }
    ).catch(
      (e) => { console.log('Error retrieving categories from database: ', e) }
    );
  };
};

export const setCategories = (categories) => ({
  type: 'SET_CATEGORIES',
  categories
});

const unsetCategories = () => ({
  type: 'UNSET_CATEGORIES',
});

export const startUnsetCategories = () => {
  return (dispatch) => {
    dispatch(unsetCategories());
  };
};

const saveCategory = (categoryId, updates) => ({
  type: 'SAVE_CATEGORY',
  categoryId,
  updates
});

export const startSaveCategory = (categoryId, { categoryName, categoryComment, categoryOrder, categoryField }) => {
  return (dispatch) => {
    const updates = {
      categoryName,
      categoryComment,
      categoryOrder,
      categoryField
    }
    database.ref(`categories/${categoryId}`).update(updates).then( () => {
      dispatch(saveCategory(categoryId, updates))
    }).catch(
      (e) => { console.log('Error saving category: ', e) }
    )
  }
}

const removeCategory = (categoryId) => ({
  type: 'REMOVE_CATEGORY',
  categoryId
});

export const startRemoveCategory = (categoryId, userId) => {
  return (dispatch) => {
    database.ref(`categories/${categoryId}`).remove().then(() => {
      dispatch(removeCategory(categoryId));
    }).catch(
      (e) => { console.log('Error removing category: ', e) }
    );
  }
}


/* --- SUGGESTIONS --- */

const saveSuggestion = (categoryId, suggestionId, updates) => ({
  type: 'SAVE_SUGGESTION',
  categoryId,
  suggestionId,
  updates
});

export const startSaveSuggestion = (categoryId, suggestionId, { suggestionName, note }) => {
  return (dispatch) => {
    const updates = {
      suggestionName,
      note
    }
    database.ref(`categories/${categoryId}/suggestions/${suggestionId}`).update(updates).then(() => {
      dispatch(saveSuggestion(categoryId, suggestionId, updates))
    }).catch(
      (e) => { console.log('Error saving suggestion: ', e) }
    )
  }
}

const removeSuggestion = (categoryId, suggestionId) => ({
  type: 'REMOVE_SUGGESTION',
  categoryId,
  suggestionId
});

export const startRemoveSuggestion = (categoryId, suggestionId) => {
  return (dispatch) => {
    database.ref(`categories/${categoryId}/suggestions/${suggestionId}`).remove().then(() => {
      dispatch(removeSuggestion(categoryId, suggestionId));
    }).catch(
      (e) => { console.log('Error removing suggestion: ', e) }
    );
  }
}