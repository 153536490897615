import React, {useState} from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import { firebase } from '../../firebase/firebase';
import { servedFrom, env } from '../../private/private';


const RetrieveProvidedData = () => {

  const [providedData, setProvidedData] = useState('');

  const handleClick = async () => {
    const retrieveProvidedData = firebase.functions().httpsCallable('retrieveProvidedData');
    const result = await retrieveProvidedData().then((result) => {
      console.log(`Provided data has been retrieved.`);
      return result.data;
    }).catch((error) => {
      console.log('Error: ', error);
    });
    if (result) {setProvidedData(JSON.stringify(result))};
  }

  return (
    <div className="retrieve-provided-data">
      <Button variant="contained" color="primary" onClick={handleClick}>Retrieve Provided Data</Button>
      <Typography gutterBottom color="secondary">
        {`Environment is ${env}.  Served from ${servedFrom}.`}
      </Typography>
      <TextField
        label="Provided Data"
        variant="outlined"
        multiline={true}
        fullWidth={true}
        value={providedData}
      >
      </TextField>
    </div>
  )
}

export default RetrieveProvidedData;