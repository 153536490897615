import React, {useEffect, useState} from 'react';
import MessagesTable from './MessagesTable';
import LoadingPage from './LoadingPage';
import { startInitMessages, startUnsetMessages } from '../actions/messages';
import { connect } from 'react-redux';
import { chooseTemplatesPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { startInitTemplatesAbout, startUnsetTemplatesAbout } from '../actions/templatesAbout';


const MessagesPage = ({dispatch, auth, messages}) => {

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(startInitTemplatesAbout())
      await dispatch(startInitMessages(auth.uid));
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch, auth.uid])

  useEffect(() => {
    if (messages === false) {
      history.push(chooseTemplatesPath());
    }
  }, [messages])

  useEffect(() => {
    return () => {
      dispatch(startUnsetTemplatesAbout())
      dispatch(startUnsetMessages())
    }
  }, [dispatch])

  return isLoading ? <LoadingPage/> :
    (
      <div id="messages-page" className="messages-page">
        <MessagesTable />
      </div>
    )
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    messages: state.messages,
    templatesAbout: state.templatesAbout
  }
}

export default connect(mapStateToProps)(MessagesPage); 