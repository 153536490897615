import React from 'react';
import { connect } from 'react-redux';
import CategoryRow from './CategoryRow';


const CategoriesGrid = ({categories}) => {

  return (
    <div>
      {Object.entries(categories)
      .sort((a, b) => (a[1].categoryName > b[1].categoryName ? 1 : -1))
      .map((entry) => {
        return (
          <CategoryRow entry={entry} key={entry[0]} />
        )
      })}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(CategoriesGrid);