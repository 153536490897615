import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import {insertText} from '../helpers/editText';
import { secondary, paper } from '../styles/styles.scss';
import { startUnsetActiveSuggestion } from '../actions/activeSuggestion';
import { connect } from 'react-redux';
import { startSetMessageSubject, startSetMessageBody } from '../actions/message';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import { ExpandMore } from '@material-ui/icons';
import { hideHighlightButtons } from '../helpers/highlightButtons';
import { startSetHighlightMode } from '../actions/highlightMode';
import { getNewFieldBeforeAndAfter } from '../helpers/editText';
import { highlightTheSuggestion, triggerInputEvent } from '../helpers/highlightText';
import { startSetMessageLastUpdated } from '../actions/messageLastUpdated';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  activeItem: {
    backgroundColor: secondary
  },
  inactiveItem: {
    backgroundColor: paper
  },
}));

const TechniqueList = ({dispatch, categoryField, activeSuggestion, auth, account, totalSuggestions, showMore, visibleSuggestionsArray, numSuggestionsShown, highlightMode, activeCategories, messageLastUpdated}) => {
  const classes = useStyles();

  const handleClick = async (suggestion) => {

    // 1. Amplitude
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (suggestion) { amplitude.logEvent('Add suggestion', { suggestion: suggestion }) };
    }

    // 2. Update highlight buttons
    if (highlightMode === 'alternatives') {
      await hideHighlightButtons(activeCategories, dispatch);
      await dispatch(startSetHighlightMode('off'));
    }
    
    // 3. Insert text
    await insertText(suggestion[1].suggestionName, categoryField, startSetMessageSubject, startSetMessageBody, dispatch, activeSuggestion);
    
    // 4. Highlight new text
    let parentId = categoryField === 'subject' ? 'subject-text-field' : 'body-text-field';
    const suggestionId = suggestion[0];
    let sugIndex;
    
    // If field is the subject 
    if (categoryField === 'subject') {
      sugIndex = 0;
    } else if (activeSuggestion) {
      sugIndex = getNewFieldBeforeAndAfter(document.getElementById(parentId), activeSuggestion).before.length
    } else {
      sugIndex = getNewFieldBeforeAndAfter(document.getElementById(parentId)).before.length
    }
    const suggestionObj = {
      text: suggestion[1].suggestionName,
      suggestionId,
      index: sugIndex,
      temporary: true
    }
    highlightTheSuggestion(suggestionObj);
    setTimeout(() => { triggerInputEvent(parentId) }, 10);


    // 5. Unset active suggestion
    if (activeSuggestion) {
      dispatch(startUnsetActiveSuggestion())
    }

    // 6. setMessageLastUpdated
    dispatch(startSetMessageLastUpdated(Date.now()));
    
  }


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folders">
        {visibleSuggestionsArray.map((suggestion, index, sugArray) => {
          return (
            <div key={suggestion[0]}>
              <ListItem
                button
                onClick={() => {handleClick(suggestion)}}
                className={activeSuggestion.suggestionId === suggestion[0] ? classes.activeItem : classes.inactiveItem}
                >
                <ListItemText primary={suggestion[1].suggestionName} />
              </ListItem>
              {index + 1 === sugArray.length ?  '' : <Divider />}
            </div>
          )
        })}
        {(numSuggestionsShown >= totalSuggestions) ? '' : <Divider />}
        {
          (numSuggestionsShown >= totalSuggestions) ? '' :
          <ListItem
            button
            onClick={() => { showMore() }}
            className={classes.inactiveItem}
          >
            <div className="technique-list__show-more">
              <ExpandMore/><Typography>Show more</Typography>
            </div>
          </ListItem>
        }
      </List>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account,
    activeSuggestion: state.activeSuggestion,
    highlightMode: state.highlightMode,
    activeCategories: state.activeCategories,
    messageLastUpdated: state.messageLastUpdated
  }
}

export default connect(mapStateToProps)(TechniqueList);