import database from '../firebase/firebase';

const initTemplatesContent = (templatesContent) => ({
  type: 'INIT_TEMPLATES_CONTENT',
  templatesContent
});

export const startInitTemplatesContent = () => {
  return (dispatch) => {
    return database.ref(`templatesContent`).once('value').then(
      async (snapshot) => {
        let templatesContent = snapshot.val();
        if (templatesContent === null || templatesContent === undefined) {templatesContent = {}}
        await dispatch(initTemplatesContent(templatesContent));
      }
    ).catch(
      (e) => { console.log('Error retrieving templatesContent from database: ', e) }
    );
  };
};

const unsetTemplatesContent = () => ({
  type: 'UNSET_TEMPLATES_CONTENT',
});

export const startUnsetTemplatesContent = () => {
  return (dispatch) => {
    dispatch(unsetTemplatesContent());
  };
};

const saveTemplateContent = (templateId, updates) => ({
  type: 'SAVE_TEMPLATE_CONTENT',
  templateId,
  updates
});

export const startSaveTemplateContent = (templateId, content ) => {
  return (dispatch) => {
    database.ref(`templatesContent/${templateId}`).update(content).then(() => {
      dispatch(saveTemplateContent(templateId, content))
    }).catch(
      (e) => { console.log('Error saving templateContent: ', e) }
    )
  }
}

const removeTemplateContent = (templateId) => ({
  type: 'REMOVE_TEMPLATE_CONTENT',
  templateId
});

export const startRemoveTemplateContent = (templateId) => {
  return (dispatch) => {
    database.ref(`templatesContent/${templateId}`).remove().then(() => {
      dispatch(removeTemplateContent(templateId));
    }).catch(
      (e) => { console.log('Error removing templateContent: ', e) }
    );
  }
}

const updateTemplateContentCategories = (templateId, updates) => ({
  type: 'UPDATE_TEMPLATE_CONTENT_CATEGORIES',
  templateId,
  updates
});

export const startUpdateTemplateContentCategories = (templateId, selectedCategoryIds) => {
  return (dispatch) => {
    database.ref(`templatesContent/${templateId}/selectedCategories`).set(selectedCategoryIds).then(() => {
      dispatch(updateTemplateContentCategories(templateId, selectedCategoryIds))
    }).catch(
      (e) => { console.log('Error updating templateContent category ids: ', e) }
    )
  }
}
