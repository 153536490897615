import React from 'react';
import Notepad from '../components/Notepad';


const NotepadContainer = () => {
  return (
    <div className="notepad-container">
      <Notepad />
    </div>
  );
}

export default NotepadContainer;