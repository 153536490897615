import * as firebase from 'firebase';
import {firebaseConfiguration} from '../private/private';

// Initialize Firebase
const config = firebaseConfiguration;
// console.log('Configuring firebase');
firebase.initializeApp(config);
firebase.analytics();
const database = firebase.database();

// // Implement authentication
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Test firebase
// firebase.database().ref('test').set('This is a test').then(
//   () => {console.log('Wrote to database')}
// ).catch(
//   (e) => {console.log('Error fetching data: ', e)}
// );

export {firebase, database as default};
