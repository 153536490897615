import React, {useEffect, useState} from 'react';
import { Typography, Button, CardActions, CardContent, Card, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { retrieveMessage } from '../helpers/messages'
import { startSetMessageContent } from '../actions/message';
import { startRemoveMessage } from '../actions/messages';
import LoadingPage from './LoadingPage';
import { messagesPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import MessageNotAvailable from './MessageNotAvailable';

const DeleteMessagePage = ({ dispatch, match, messages, auth, account }) => {

  const { messageId } = match.params;

  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [messageNotAvailable, setMessageNotAvailable] = useState(false)

  // Retrieve the message and put it in the message content 
  useEffect(() => {
    if (auth.uid) {
      const fetchData = async () => {
        let message;
        try {
          message = await retrieveMessage(auth.uid, messageId);
        } catch (error) {
          setIsLoading(false);
          setMessageNotAvailable(true);
        }
        if (message) {
          dispatch(startSetMessageContent(message));
          setSubject(message.subject);
          setBody(message.body);
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [dispatch, messageId, auth.uid])

  const handleBack = () => {
    history.push(messagesPath);
  }

  const handleDelete = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (messageId && subject && body) { amplitude.logEvent('Delete message', { messageId, subject, body }) };
    }
    dispatch(startRemoveMessage(messageId, auth.uid))
    history.push(messagesPath);
  }


  if (isLoading) {
    return <LoadingPage />
  } else if (messageNotAvailable) {
    return <MessageNotAvailable navbar={false} />
  } else {
    return (
      <div className="get-started">
        <Card className="intro-card">
          <CardContent>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Are you sure you want to delete this message?
          </Typography>
            <Typography color="secondary" component="p" className="" gutterBottom={true}>
              This action is permanent.
          </Typography>
            <div className="subject">
              <form noValidate autoComplete="off">
                <span className="subject__line" >
                  <div className="delete-message-page__subject-field-container">
                    <TextField
                      fullWidth={true}
                      multiline={true}
                      id="subject-text-field"
                      value={subject}
                      variant="outlined"
                    />
                  </div>
                </span>
              </form>
            </div>
            <div className="body">
              <div>
                <TextField
                  multiline={true}
                  fullWidth={true}
                  rows={5}
                  variant="outlined"
                  value={body}
                />
              </div>
            </div>
          </CardContent>
          <CardActions className="delete-message-page__actions">
            <Button variant="contained" color="primary" onClick={handleBack}>Back</Button>
            <Button variant="outlined" color="secondary" onClick={handleDelete}>Delete</Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(DeleteMessagePage);