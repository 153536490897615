import React, { useState } from 'react';
import { TextField, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Save, Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import {startSaveCategory, startRemoveCategory} from '../../actions/categories';
import { replaceDumbText } from '../../helpers/editText';

const CategoryRow = ({ dispatch, entry }) => {

  const categoryId = entry[0];
  const [categoryName, setCategoryName] = useState(entry[1].categoryName);
  const [categoryComment, setCategoryComment] = useState(entry[1].categoryComment);
  const [categoryField, setCategoryField] = useState(entry[1].categoryField ? entry[1].categoryField : '');
  const [categoryOrder, setCategoryOrder] = useState(entry[1].categoryOrder);
  const [confirm, setConfirm] = useState('');


  const handleSave = () => {
    dispatch(startSaveCategory(categoryId, { categoryName, categoryComment, categoryOrder, categoryField }));
    console.log('handleSave');
  }

  const handleDelete = () => {
    if (confirm === 'yes') {
      dispatch(startRemoveCategory(categoryId));
    } else {
      console.log('You must confirm deletion before deleting');
    }
    console.log('handleDelete');
  }


  return (
      <div className="admin-category-row">
        <div className="admin-text-small">
          <TextField id={entry[0]} label='catId' fullWidth={true} value={categoryId} />
        </div>
        <div className="admin-text-medium">
          <TextField id={entry[1].categoryName} label='categoryName' fullWidth={true} value={categoryName} onChange={(e) => { setCategoryName(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-medium">
          <TextField id={entry[1].categoryComment} label='categoryComment' fullWidth={true} value={categoryComment} onChange={(e) => { setCategoryComment(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-small">
          <FormControl>
            <InputLabel id="demo-simple-select-label">categoryField</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categoryField}
              onChange={(e) => { setCategoryField(e.target.value) }}
            >
              <MenuItem value="subject" key="subject">subject</MenuItem>
              <MenuItem value="body" key="body">body</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="admin-text-small">
          <TextField id={entry[1].categoryOrder} label='categoryOrder' fullWidth={true} value={categoryOrder} onChange={(e) => { setCategoryOrder(e.target.value) }} />
        </div>
      <div className="admin-text-small">
        <TextField id={entry[1].categoryOrder} label='sugs' fullWidth={true} value={entry[1].suggestions ? Object.keys(entry[1].suggestions).length : 0} />
      </div>
        <div className="admin-text-small">
          <TextField id='confirm' label='yes' fullWidth={true} value={confirm} onChange={(e) => { setConfirm(e.target.value) }} />
        </div>
        {/* <TextField id={entry[1]} label='categoryComment' fullWidth={true} value={categoryComment} onChange={(e) => { setCategoryComment(e.target.value) }} /> */}
        <div>
          <IconButton id='save-category' variant="contained" onClick={() => { handleSave() }}><Save /></IconButton>
          <IconButton id='delete-category' variant="contained" onClick={() => { handleDelete() }}><Delete /></IconButton>
        </div>
      </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps)(CategoryRow);