import React, {useState, useEffect} from 'react';
import { Typography, Button, CardActions, CardContent, Card } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { getStartedTemplateId } from '../helpers/constants';
import { retrieveTemplateAbout } from '../helpers/templatesAbout';
import CreateMessageButton from './CreateMessageButton';
import { getStartedPath } from '../helpers/links';
import { history } from '../routers/AppRouter';

export default ({ dispatch }) => {

  const [template, setTemplate] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      let template = await retrieveTemplateAbout(getStartedTemplateId);
      setTemplate(template);
    }
    fetchData();
  }, [])

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            The scenario
          </Typography>
          <Typography color="textSecondary" component="p" className="" gutterBottom={true}>
            Suppose you run an online store called “Summer Sox.”  You want to offer a discount to promote your new socks.  SplashPad will help you communicate the discount to your audience so that you grab their attention and sell more.
          </Typography>
          {/* <Typography color="textSecondary" className="get-started__below-divider">
            When you finish, you'll earn an exclusive <b>$250 discount</b> on your first year of SplashPad!
          </Typography> */}
        </CardContent>
        <CardActions className="template-card__details">
          <Button variant="outlined" style={{ borderRadius: 25 }} startIcon={<ArrowBack />} onClick={() => history.push(getStartedPath)}>Back</Button>
          <CreateMessageButton getStarted={true} entry={[getStartedTemplateId, template]}/>
        </CardActions>
      </Card>
    </div>
  );
}