import React from 'react';
import { Typography, Button, CardActions, CardContent, Card, Link } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { contactPath, pricingPath } from '../helpers/links';
import { history } from '../routers/AppRouter';


export default () => {

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            We're sorry
          </Typography>
          <Typography color="textSecondary" component="p" className="" gutterBottom={true}>
            The Enterprise Plan is not yet available.
          </Typography>
          <Typography color="textSecondary" className="get-started__below-divider">
            If you sign up for the <Link href={contactPath}>Starter plan</Link>, we will notify you when you can upgrade.
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" startIcon={<ArrowBack />} onClick={() => { history.push(pricingPath) }}>See plans</Button>
        </CardActions>
      </Card>
    </div>
  );
}