import { returnSuggestionsArray } from '../helpers/categories';
import { getIndex } from '../helpers/highlightButtons';
import { fields } from '../helpers/constants';
require('highlight-within-textarea');

const $ = window.$;

export const initJqueryHighlightPluginToNone = () => {
  const defaultHighlightObject = [{
    highlight: '',
    className: `hwt__custom-blue`
  }]
  fields.forEach(async (field) => {
    return await $(field.fieldId).highlightWithinTextarea({ highlight: defaultHighlightObject});
  });
}

export const destroyJqueryHighlightPlugin = () => {
  fields.forEach((field) => {
    $(field.fieldId).highlightWithinTextarea('destroy')
  });
}

export const highlightActiveSuggestions = (activeCategories) => {
  fields.forEach((field) => {
    const suggestionsArray = returnSuggestionsArray(activeCategories);
    const splitSuggestionsArray = returnSplitSuggestionsArray(suggestionsArray);
    const highlightObject = splitSuggestionsArray.map((suggestion) => {
      return [{
        highlight: suggestion[3],
        className: `hwt__custom-blue" ancestor="${field.ancestor}" id="${suggestion[2]}`
      }]
    })
    $(field.fieldId).highlightWithinTextarea({ highlight: highlightObject })
  })
}

export const highlightTheSuggestion = async ({text, suggestionId, index, temporary}) => {
  const highlightObject = [{
    highlight: text,
    className: `hwt__custom-blue" id="${suggestionId}`
  }]
  fields.forEach((field) => {
    $(field.fieldId).highlightWithinTextarea({ highlight: highlightObject })
  })
  setTimeout(() => {
    hideExtraInstancesOfMark(suggestionId, index, temporary);
  }, 30)
}

// If there are multiple instances of this mark, add a disabled class to the others.
const hideExtraInstancesOfMark = (suggestionId, index, temporary) => {
  const elems = document.querySelectorAll(`mark[id='${suggestionId}']`)
  elems.forEach((elem) => {
    if (getIndex(elem) !== index) {
      let newElem = elem.cloneNode(true);
      newElem.setAttribute('class', 'hwt__disabled-mark');
      elem.parentNode.replaceChild(newElem, elem);
    } else {
      let newElem = elem.cloneNode(true);
      if (temporary) {newElem.setAttribute('class', 'hwt__custom-blue-clicked')};
      elem.parentNode.replaceChild(newElem, elem);
    }
  })
}

export const returnSplitSuggestionsArray = (suggestionsArray) => {
  let splitSuggestionsArray = [];
  suggestionsArray.forEach((suggestion) => {
    let suggestionText = suggestion[3].trim();

    // If there is a [] at the beginning, remove it from the suggestionText
    if (suggestionText[0] === '[' && suggestionText.includes(']')) {
      const beginningIndex = suggestionText.indexOf(']') + 1;
      suggestionText = suggestionText.substring(beginningIndex);
    }

    // If there is a [] at the end, remove it from the suggestionText
    if (suggestionText[suggestionText.length - 1] === ']' && suggestionText.includes('[')) {
      const endingIndex = suggestionText.lastIndexOf('[');
      suggestionText = suggestionText.substring(0, endingIndex);
    }
    
    // Create a regex with the suggestion text, replacing any [] in the middle with an expression that captures
    // any character (unlimited number of characters) except punctuation, which indicates the end of a sentence.
    suggestionText = suggestionText.trim()
    const bracketRegex = /\[.*?\]/g;
    suggestionText = suggestionText.replace(bracketRegex, '[^.!?:;]*');
    suggestionText = new RegExp(suggestionText, 'gi')

    let newSuggestion = [...suggestion];
    newSuggestion[3] = suggestionText;
    splitSuggestionsArray.push(newSuggestion);
  })
  return splitSuggestionsArray;
}

// the jQuery plugin only updates highlights when input events are triggered from the textarea.
// Updating the value of the field programmatically (i.e., controlled field) does not trigger that event.
// That's why, after the user clicks on a technique line item to update the field programmatically, I am dispatching the input event.
export const triggerInputEvent = (fieldId) => {
  $(`#${fieldId}`).highlightWithinTextarea('update');
}