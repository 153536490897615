import smartquotes from 'smartquotes';

export const insertText = async (value, categoryField, startSetMessageSubject, startSetMessageBody, dispatch, activeSuggestion) => {
  if (categoryField === 'subject') {
    return await dispatch(startSetMessageSubject(value))
  } else {
    const bodyElem = document.getElementById('body-text-field');
    const { before, after } = getNewFieldBeforeAndAfter(bodyElem, activeSuggestion);
    const newBody = before.concat(value, after);
    return await dispatch(startSetMessageBody(newBody));
  }
}

export const getNewFieldBeforeAndAfter = (myField, activeSuggestion) => {

  // If the field has no value, just add the text.
  if (!myField.value || myField.value.length === 0) {
    // console.log('1');
    return {before: '', after: ''};

    // If there was an activeSuggestion, replace that suggestion text.
  } else if (myField.value.length > 0 && activeSuggestion) {
    // console.log('2');
    const before = myField.value.substring(0, activeSuggestion.index);
    const after = myField.value.substring(activeSuggestion.index + activeSuggestion.innerTextLength);
    return {before, after};

    // If the field has a value and there is no cursor focused on the field, add a line break to the end followed by text
  } else if (myField.value.length > 0 && !myField.selectionStart) {
    // console.log('3');
    let before = myField.value;
    if (before.endsWith('\n\n')) {
      // Do nothing
    } else if (before.endsWith('\n')) {
      before = before.concat('\n');
    } else {
      before = before.concat('\n\n');
    }
    return {before, after: ''}

    // If the field has a value and the cursor is focused at the beginning, add text to beginning followed by line break.
    // This one is never invoked.  It is caught by scenario 2 (immediately above) because when a text field is not in focus, myField.selectionStart is treated as false AND given a value of 0.
    // So it's not possible to distinguish the case when the cursor is at the beginning (here) and where the cursor is not in focus (scneario 2).
  } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === '0') {
    // console.log('4');
    const before = '';
    const after = "\n" + myField.value;
    return {before, after}

    // If the field has a value and the cursor is focused at the end, add a line break followed by value followed by a line break.
  } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === myField.value.length) {
    // console.log('5');
    const before = myField.value + "\n";
    return {before, after: ''}

    // If the field has a value the cursor is focused in the middle, add a line break on either side of text added.
  } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart !== '0' && myField.selectionStart !== myField.value.length) {
    // console.log('6');
    var startPos = myField.selectionStart;
    var endPos = myField.selectionEnd;
    const before = myField.value.substring(0, startPos) + "\n";
    const after = "\n" + myField.value.substring(endPos);
    return {before, after}
  }

}

export const replaceDumbText = (str = '') => {
  let result = str;
  if (str.includes(`'`)) {
    const dumbApostrophe = /'/g;
    result = str.replace(dumbApostrophe, '’');
  }
  if (str.includes(`"`)) {
    result = smartquotes(result);
  }
  return result;
}

/* Old function */
// export const getNewBody = (myField, myValue, activeSuggestion) => {
//   console.log('1');

//   // If the field has no value, just add the text.
//   if (!myField.value || myField.value.length === 0) {
//     console.log('2');
//     console.log('insertionIndex: ', myField.value.length);
//     return myField.value + myValue;

//   // If there was an activeSuggestion, replace that suggestion text.
//   } else if (myField.value.length > 0 && activeSuggestion) {
//     console.log('3');
//     const before = myField.value.substring(0, activeSuggestion.index);
//     const after = myField.value.substring(activeSuggestion.index + activeSuggestion.innerTextLength);
//     console.log('insertionIndex: ', before.length);
//     return before + myValue + after;

//   // If the field has a value and there is no cursor focused on the field, add a line break to the end followed by text
//   } else if (myField.value.length > 0 && !myField.selectionStart) {
//     console.log('4');
//     const result = myField.value + "\n" + myValue;
//     console.log(result);
//     let before = myField.value + "\n";
//     console.log('insertionIndex', before.length);
//     return myField.value + "\n" + myValue;

//   // If the field has a value and the cursor is focused at the beginning, add text to beginning followed by line break.
//   // This one is never invoked.  It is caught by scenario 2 (immediately above) because when a text field is not in focus, myField.selectionStart is treated as false AND given a value of 0.
//   // So it's not possible to distinguish the case when the cursor is at the beginning (here) and where the cursor is not in focus (scneario 2).
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === '0') {
//     console.log('5');
//     let before = myField.value + "\n";
//     console.log('insertionIndex', before.length);
//     return myValue + "\n" + myField.value;

//   // If the field has a value and the cursor is focused at the end, add a line break followed by value followed by a line break.
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === myField.value.length) {
//     console.log('6');
//     let before = myField.value + "\n";
//     console.log('insertionIndex', before.length);
//     return myField.value + "\n" + myValue;

//     // If the field has a value the cursor is focused in the middle, add a line break on either side of text added.
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart !== '0' && myField.selectionStart !== myField.value.length) {
//     console.log('7');
//     var startPos = myField.selectionStart;
//     var endPos = myField.selectionEnd;
//     let before = myField.value.substring(0, startPos) + "\n";
//     console.log('insertionIndex', before.length);
//     return myField.value.substring(0, startPos) + "\n" + myValue + "\n" + myField.value.substring(endPos, myField.value.length);
//   }
// }

/* Old function 2 */
// export const returnInsertionIndex = (myField, activeSuggestion) => {

//   console.log('A 1');

//   // If the field has no value, return 0.
//   if (!myField.value || myField.value.length === 0) {
//     console.log('A 2');
//     return 0;

//     // If there was an activeSuggestion, return the activeSuggestionIndex
//   } else if (myField.value.length > 0 && activeSuggestion) {
//     console.log('A 3');
//     return activeSuggestion.index;

//     // If the field has a value and there is no cursor focused on the field, go to the end of the field
//   } else if (myField.value.length > 0 && !myField.selectionStart) {
//     console.log('A 4');
//     const before = myField.value + "\n";
//     return before.length;

//     // If the field has a value and the cursor is focused at the beginning, add text to beginning followed by line break.
//     // This one is never invoked.  It is caught by scenario 2 (immediately above) because when a text field is not in focus, myField.selectionStart is treated as false AND given a value of 0.
//     // So it's not possible to distinguish the case when the cursor is at the beginning (here) and where the cursor is not in focus (scneario 2).
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === '0') {
//     console.log('A 5');
//     return 0;

//     // If the field has a value and the cursor is focused at the end, add a line break followed by value followed by a line break.
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart === myField.value.length) {
//     console.log('6');
//     const before = myField.value + "\n";
//     return before.length;

//     // If the field has a value the cursor is focused in the middle, add a line break on either side of text added.
//   } else if (myField.value.length > 0 && myField.selectionStart && myField.selectionStart !== '0' && myField.selectionStart !== myField.value.length) {
//     console.log('7');
//     var startPos = myField.selectionStart;
//     const before = myField.value.substring(0, startPos) + "\n";
//     return before.length;
//   }

// }