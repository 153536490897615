export const copyMessage = (dispatch, message, startSetCopySnackbar) => {
  copySubjectAndBody(message)
  dispatch(startSetCopySnackbar(true));
}

const copySubjectAndBody = (notepadContent) => {
  const subjectText = notepadContent.subject;
  const bodyText = notepadContent.body;
  const message = `${subjectText}\n \n${bodyText}`;
  let dummy = document.createElement("textarea");
  // dummy.style.display = 'none';
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = message;

  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const copyBody = () => {
  /* Get the text field */
  var copyText = document.getElementById("body-text-field");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
}


