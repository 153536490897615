import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link, Button, Toolbar, AppBar} from '@material-ui/core';

import { domain, contactPath, messagesPath, pricingPath } from '../helpers/links'
import { paper, appbar } from '../styles/styles.scss';
import { connect } from 'react-redux';
import AvatarMenu from './AvatarMenu';
import { useLocation } from 'react-router-dom'
import { history } from '../routers/AppRouter';
import { statusIsTrialorPaidorTrialInactive } from '../helpers/auth';
import SmallNavbarDropdown from './SmallNavbarDropdown';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: paper,
  },
  appbar: {
    backgroundColor: appbar
  }
}));



const Navbar = ({auth}) => {
  
  let location = useLocation().pathname
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <div className="toolbar">
            <Link href={domain} >
              <img src="/images/logo-white.png" height="36px" alt="SplashPad"></img>
            </Link>
            <div className="toolbar__end-links">
              <div className="show-when-xs">
                <SmallNavbarDropdown />
              </div>

              {/* If the user has not signed in AND subsribed, show the messages link */}
              {statusIsTrialorPaidorTrialInactive(auth.roles) ? (
                <div className="toolbar__button hide-when-xs">
                  <Button
                    color="inherit"
                    onClick={() => { history.push(messagesPath) }}
                    style={{
                      backgroundColor: 'transparent',
                      borderBottom: location === messagesPath ? "2px solid white" : "0px",
                      borderRadius: 0,
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginLeft: "8px",
                      marginRight: "8px"
                    }}
                  >Your messages
                  </Button>
                </div>
              ) : ''
              }

              {/* If the user has signed in, show the contact button */}
              {!auth.uid ? '' :
                <div id="contact-button" className="toolbar__button hide-when-xs">
                  <Button
                    color="inherit"
                    onClick={() => {history.push(contactPath)}}
                    style={{ 
                      backgroundColor: 'transparent',
                      borderBottom: location === contactPath ? "2px solid white": "0px",
                      borderRadius: 0,
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      marginLeft: "8px",
                      marginRight: "8px"
                    }}
                  >Contact us
                  </Button>
                </div>
              }

              {/* If the user is signed in but has not subscribed, show the subscribe path. */}
              {auth.uid && !statusIsTrialorPaidorTrialInactive(auth.roles) ? (
                <div id="subscribe-button" className="toolbar__button hide-when-xs">
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => { history.push(pricingPath) }}
                    style={{
                      marginLeft: "8px",
                      marginRight: "8px"
                    }}
                  >Subscribe!
                  </Button>
                </div>
                ) : ''
              }

              {/* If the user has signed in, show the avatar menu */}
              {!auth.uid ? '' : 
                <div className="toolbar__button">
                  <AvatarMenu type='navbar' />
                </div>
              }

            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Navbar);