import React from 'react';
import { Typography, Button, CardActions, CardContent, Card, Link } from '@material-ui/core';
import { domain, contactPath } from '../helpers/links';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';


const BuyFailure = () => {

  if (amplitudeFlag) {
    amplitude.logEvent('Reached BuyFailure');
  }


  return (
    <div className="get-started">
        <Card className="intro-card">
          <CardContent>
            <Typography variant="h4" color="textSecondary" gutterBottom>
              There was a problem.
            </Typography>
            <Typography>
              Your purchase could not be completed.  If the problem persists, please <Link to={contactPath}>contact us</Link>.
            </Typography>
          </CardContent>
          <CardActions className="delete-message-page__actions">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => window.location.replace(domain)}
            >
              Back to home
            </Button>
          </CardActions>
        </Card>
    </div>
  );
}

export default BuyFailure;