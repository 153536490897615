import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import Navbar from '../components/Navbar';
import { signinPath } from '../helpers/links';
import LoadingPage from '../components/LoadingPage';
import Navbar from '../components/Navbar';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import Fallback from '../components/Fallback';
var _ = require('lodash');


const PrivateNavRoute = ({
  auth,
  noFooter = false,
  roleMustBe,
  component: Component,
  ...rest
}) => {

  const roleCanAccess = () => {
    if (roleMustBe === null || roleMustBe === undefined) {
      return true
    } else {
      let result = false;
      const givenRoles = Object.entries(auth.roles).map((entry) => {
        const obj = {};
        obj[entry[0]] = entry[1];
        return obj;
      })
      givenRoles.forEach((givenRole) => {
        roleMustBe.forEach((permittedRole) => {
          if (_.isEqual(givenRole, permittedRole)) { result = true };
        })
      })
      return result;
    }
  }

  return (
    <Route {...rest} component={(props) => {
      if (auth === 'loading') {
        return <LoadingPage />
      } else if (auth.uid && roleCanAccess()) {
        return (
          <div className="page-container">
            <div>
              <Navbar />
              <Component {...props} />
            </div>
            {noFooter ? '' : <Footer />}
          </div>
        )
      } else if (auth.uid && !roleCanAccess()) {
        return <Fallback fallbackMessage={`We're sorry.  You don't have permission to access that page.`} />
      } else {
        return <Redirect to={`${signinPath}${props.history.location.pathname}`} />
      }
    }} />
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(PrivateNavRoute);