import React from "react";
import {Button, Typography, Paper, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from './LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    maxWidth: '300px'
  },
  textFieldContainer: {
    maxWidth: '300px',
    marginTop: '8px',
    marginBottom: '16px'
  }
}));

export default ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size
}) => {
  const classes = useStyles();

  return (

    <div {...tooltipProps} id="tooltip-body">
      <Paper className={classes.root} elevation={5}>
        {/* <Typography>{step.title}</Typography>} */}
        {/* <div><Typography variant="h6">Step {index + 1} of {size}</Typography></div> */}
        <LinearProgress progress={ index / size * 100} />
        <div id="tooltip-content">
          <Typography gutterBottom={true}>{step.content.body}</Typography>
        </div>
          {!step.content.textarea ? '' :
            <div className={classes.textFieldContainer} id="content-tip-text-field-container">
                <TextField
                  multiline={true}
                  fullWidth={true}
                  rows='10'
                  id="content-tip-text-area"
                  variant="outlined"
                /> 
            </div>
          }
        <div id='tooltip-footer'>
          {index > 0 && step.content.back && (
            <Button
              variant="outlined"
              color='primary'
              {...backProps}
            >
              Back
            </Button>
          )}
          {continuous && step.content.next && (
            <Button
              variant="contained"
              color='primary'
              {...primaryProps}
            >
              {typeof step.content.next === 'string' ? step.content.next : 'Next'}
            </Button>
          )}
          {!continuous && step.content.close && (
            <Button
              variant="contained"
              primary={true}
              {...closeProps}
            >
              Close
            </Button>
          )}
          {continuous && step.content.final && (
            <Button
              variant="contained"
              color='primary'
              onClick={() => {step.content.finalFunction()}}
            >
              {typeof step.content.final === 'string' ? step.content.final : 'Close'}
            </Button>
          )}
        </div>
      </Paper>
    </div>
  );
}

