import { returnSplitSuggestionsArray } from '../helpers/highlightText';

export const orderCategories = (categoriesArray) => {
  return categoriesArray.sort((a, b) => {
    if (a[1].categoryOrder) {
      return (a[1].categoryOrder > b[1].categoryOrder) ? 1 : -1;
    } else {
      return -1;
    }
  })
}

export const filterToActiveCategories = (selectedCategories, categories) => {
  let result = {};
  if (selectedCategories === undefined || !selectedCategories || selectedCategories === {}) {
    return categories;
  } else {
      Object.keys(selectedCategories).forEach((catId) => {
      if (Object.keys(categories).includes(catId)) {
        result[catId] = categories[catId];
      }
    });
  }
  return result;
}

export const returnSuggestionsArray = (categories) => {
  let result = [];
  Object.entries(categories).forEach((entry) => {
    const categoryId = entry[0];
    const categoryName = entry[1].categoryName;
    const categoryField = entry[1].categoryField;
    const suggestions = entry[1].suggestions ? entry[1].suggestions : {};
    if (suggestions) {
      Object.entries(suggestions).forEach((sug) => {
        const suggestionId = sug[0];
        const suggestionName = sug[1].suggestionName;
        const note = sug[1].note;
        result.push([categoryId, categoryName, suggestionId, suggestionName, note, categoryField]);
      })
    }
  })
  return result;
}

// Return an object with selectedCategoryId.  If subject, return 1, if body, return index in template + 2
// The + 1 and +2 is because sorting does not work with 0 and negative numbers.
export const returnCategoryOrder = (categories, templateBody) => {

  // Find suggestions
  const suggestionsArray = returnSuggestionsArray(categories);
  const splitSuggestionsArray = returnSplitSuggestionsArray(suggestionsArray);

  // Create an order object that holds the index + constants of each template, used for sorting
  let orders = {};
  splitSuggestionsArray.forEach((entry) => {
    const categoryId = entry[0];
    if (orders[categoryId]) {
      // console.log(`category ${categoryId} is already ordered.`);
    } else if (entry[5] === 'subject') {
      orders[categoryId] = 1;
    } else {
      // Remove the g flag from the regex so that we get an array with index back from match()
      let regexString = entry[3].toString();
      regexString = regexString.substring(1, regexString.length - 3);
      const newRegex = new RegExp(regexString, 'i');
      // If a match was found AND it's the first match
      const match = templateBody.match(newRegex);
      if (match && match.index) {
        let index = match.index;
        orders[categoryId] = index;
        if (orders[categoryId] === index) {
          // console.log('entry 4: , orders[categoryId]', orders[categoryId]);
        }
      }
    }
  });
  // console.log('orders 1: ', orders);
  Object.entries(categories).forEach((entry) => {
    const categoryId = entry[0];
    if (!orders[categoryId]) { orders[categoryId] = 999999 }
  })
  // console.log('orders: ', orders);
  return orders;
}

export const removeCategoriesWithoutSuggestion = (categories) => {
  if (!categories || typeof categories !== 'object') {
    return categories;
  } else {
    let result = {};
    Object.keys(categories).forEach((categoryId) => {
      if (categories[categoryId] && categories[categoryId].suggestions && Object.keys(categories[categoryId].suggestions).length > 0) {
        result[categoryId] = categories[categoryId];
      }
    });
    return result;

  }

}

export const returnNumSuggestionsUsed = (categories, templatesContent) => {
  let categoriesUseObj = {};
  Object.values(templatesContent).forEach((entry) => {
    const selectedCategories = entry.selectedCategories ? entry.selectedCategories : {}
    const cats = !Object.keys(selectedCategories) ? [] : Object.keys(selectedCategories);
    cats.forEach((catId) => {
      categoriesUseObj[catId] = true;
    })
  });
  const categoriesUsed = Object.keys(categoriesUseObj);
  let numSuggestionsUsed = 0;
  categoriesUsed.forEach((categoryId) => {
    const sugs = !categories[categoryId] || !categories[categoryId].suggestions ? {} : categories[categoryId].suggestions
    const suggestionsUsed = Object.keys(sugs).length;
    numSuggestionsUsed += suggestionsUsed;
  })
  return numSuggestionsUsed;
}