import React from 'react';
import { connect } from 'react-redux';
import JSONPretty from 'react-json-pretty';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');


const ShowState = ({categories}) => {

  return (
    <div>
      <h2>State</h2>
      <div>
        <JSONPretty data={categories} theme={JSONPrettyMon}></JSONPretty>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(ShowState);