import database from '../firebase/firebase';

const initTrialActivity = (trialActivity) => ({
  type: 'INIT_TRIAL_ACTIVITY',
  trialActivity
});

export const startInitTrialActivity = (uid) => {
  return (dispatch) => {
    return database.ref(`users/${uid}/trialActivity`).once('value').then(
      async (snapshot) => {
        let trialActivity = snapshot.val();
        if (trialActivity === null || trialActivity === undefined) { trialActivity = false }
        return await dispatch(initTrialActivity(trialActivity));
      }
    ).catch(
      (e) => { console.log('Error retrieving trialActivitiy from database: ', e) }
    );
  };
};

const unsetTrialActivity = () => ({
  type: 'UNSET_TRIAL_ACTIVITY',
});

export const startUnsetTrialActivity = () => {
  return (dispatch) => {
    dispatch(unsetTrialActivity());
  };
};