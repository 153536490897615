import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';


const CategoryUsage = ({ templatesAbout, templatesContent, categories }) => {

  const returnTemplatesWithThisCategory = (categoryEntry, templatesContent, templatesAbout) => {
    let result = [];
    Object.entries(templatesContent).forEach((template) => {
      if (template[1].selectedCategories && template[1].selectedCategories[categoryEntry[0]]) {
        result.push(template[0])
      }
    });
    result = result.map((templateId) => {
      if (templatesAbout[templateId] && templatesAbout[templateId].name) {
        return templatesAbout[templateId].name;
      } else {
        return templateId;
      }
    });
    return result;
  }


  return (
    <div>
      Category Usage
      {Object.entries(categories).map((entry) => {

        const templatesWithThisCategory = returnTemplatesWithThisCategory(entry, templatesContent, templatesAbout);

        return (
          <div entry={entry[0]} key={entry[0]} style={{borderBottom: '1px solid'}}>
            <Typography>Category {entry[1].categoryName} is in templates: {templatesWithThisCategory.length}</Typography>
            <div>{templatesWithThisCategory.map((templateName) => {
              return <Typography variant="body2" key={templateName}>{templateName}</Typography>
            })}</div>
          </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    templatesAbout: state.templatesAbout,
    templatesContent: state.templatesContent,
    categories: state.categories

  }
}

export default connect(mapStateToProps)(CategoryUsage);