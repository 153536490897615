import React from 'react';
import { ButtonGroup, IconButton, Tooltip} from '@material-ui/core';
import { Undo, Redo } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const UndoRedoButton = ({ dispatch, canRedo, canUndo }) => {
  const classes = useStyles();

  const handleUndo = () => {
    if (canUndo) { dispatch(UndoActionCreators.undo()) }
  }

  const handleRedo = () => {
    if (canRedo) { dispatch(UndoActionCreators.redo()) }
  }

  return (
    <div className={classes.root}>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Undo"><IconButton color="inherit" size="small" onClick={handleUndo}><Undo /></IconButton></Tooltip>
        <Tooltip title="Redo"><IconButton color="inherit" size="small" onClick={handleRedo}><Redo /></IconButton></Tooltip>
      </ButtonGroup>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    canUndo: state.message.past.length > 0,
    canRedo: state.message.future.length > 0,
  }
}

export default connect(mapStateToProps)(UndoRedoButton);