import React from 'react';
import { Menu, MenuItem, ButtonGroup, Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { startInitChangesSinceSave } from '../actions/changesSinceSave';
import { startSaveMessage } from '../actions/messages';
import { startSetMessageContent } from '../actions/message';
import { startSetSaveSnackbar } from '../actions/saveSnackbar';
import { copyMessage } from '../helpers/copying';
import { startSetCopySnackbar } from '../actions/copySnackbar';
import { FileCopy } from '@material-ui/icons';


const SaveButtonGroup = ({ dispatch, type, message, auth, account }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (event) => {
    if (Boolean(anchorEl)) {
      setAnchorEl(false)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleSave = async () => {
    const messageId = !!message.messageId ? message.messageId : uuid()
    const lastSaved = Date.now();
    const created = !!message.created ? message.created : lastSaved;
    const title = !!message.title ? message.title : '';
    const templateId = !!message.templateId ? message.templateId : '';
    const mes = {
      ...message,
      messageId,
      lastSaved,
      created,
      title,
      templateId
    }
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      const formattedMessage = {
        ...mes,
        lastSaved: mes.lastSaved ? moment(mes.lastSaved).format("MMM Do YYYY") : '',
        created: mes.created ? moment(mes.created).format("MMM Do YYYY") : ''
      }
      if (mes) { amplitude.logEvent('Save message', { message: formattedMessage }) };
    }
    await dispatch(startSaveMessage(auth.uid, messageId, mes));
    await dispatch(startInitChangesSinceSave());
    await dispatch(startSetMessageContent(mes));
    dispatch(startSetSaveSnackbar(true));
  }

  const handleCopy = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      const formattedMessage = {
        ...message,
        lastSaved: message.lastSaved ? moment(message.lastSaved).format("MMM Do YYYY") : '',
        created: message.created ? moment(message.created).format("MMM Do YYYY") : ''
      }
      if (message) { amplitude.logEvent('Copy message', { message: formattedMessage }) };
    }
    copyMessage(dispatch, message, startSetCopySnackbar);
    setAnchorEl(false);
  }


  return (
    <div>
      <ButtonGroup variant="contained" color="primary" aria-label="split button">
        <Button onClick={handleSave} color="default">Save</Button>
        <Button
          color="default"
          size="small"
          // aria-controls={anchorEl ? 'split-button-menu' : undefined}
          // aria-expanded={anchorEl ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => handleToggle()}
      >
        <MenuItem onClick={handleCopy}>
          <div className="avatar-menu__item"><span className="avatar-menu__icon"><FileCopy /></span>Copy</div>
        </MenuItem>
      </Menu>
    </div>
  )

}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    message: state.message.present,
    account: state.account
  }
}

export default connect(mapStateToProps)(SaveButtonGroup);
