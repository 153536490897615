import React, {useState} from 'react';
import { Grid, Typography, Button, CardContent, Card, TextField, Link, FormLabel } from '@material-ui/core';
import { isPasswordError } from '../../helpers/formValidation';
import { firebase } from '../../firebase/firebase';
import { history } from '../../routers/AppRouter';
import { accountPath, forgotPasswordPath } from '../../helpers/links';
import { connect } from 'react-redux';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';


const UpdatePassword = ({auth, account}) => {

  const [submissionError, setSubmissionError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [verifyNewPassword, setVerifyNewPassword] = useState('');
  const [verifyNewPasswordError, setVerifyNewPasswordError] = useState(false);
  const [updateSuccessful, setUpdateSuccesful] = useState(false);


  const handleSubmit = () => {
    let errors = false;
    if (isPasswordError(currentPassword)) {
      setCurrentPasswordError(isPasswordError(currentPassword));
      errors = true;
    }
    if (isPasswordError(newPassword)) {
      setNewPasswordError(isPasswordError(newPassword));
      errors = true;
    }
    if (verifyNewPassword !== newPassword) {
      setVerifyNewPasswordError(`This does not match the password you entered above.`)
      errors = true;
    }
    if (!errors) {
      submit();
    }
  }

  const submit = () => {

    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      amplitude.logEvent('Attempt password update');
    }


    var user = firebase.auth().currentUser;
    user.updatePassword(newPassword).then(() => {
      console.log('Password successfully updated');
      if (amplitudeFlag) {
        if (auth.uid) { amplitude.setUserId(auth.uid) };
        amplitude.logEvent('Password successfully updated');
      }
      setUpdateSuccesful(true);
    }).catch(function (error) {
      var errorCode = error.code;
      if (errorCode === 'auth/weak-password') {
        setSubmissionError('Could not update your password because your password was not strong enough.  Please use a stronger password.');
        console.log(error);
      } else if (errorCode === 'auth/requires-recent-login') {
        setSubmissionError('Could not update your password because your last sign in was too long ago.  Please sign out and sign in again before updating your password.');
        console.log(error);
        console.log(error.message)
      }
    });
  }

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Update your password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={currentPassword}
                label="Current password"
                onChange={(e) => { setCurrentPassword(e.target.value); setCurrentPasswordError(false) }}
                variant="outlined"
                type="password"
                autoComplete="current-password"
                // required
                fullWidth
                helperText={currentPasswordError ? currentPasswordError : ''}
                error={!!currentPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={newPassword}
                label="New password"
                onChange={(e) => { setNewPassword(e.target.value); setNewPasswordError(false) }}
                variant="outlined"
                type="password"
                autoComplete="new-password"
                // required
                fullWidth
                helperText={newPasswordError ? newPasswordError : ''}
                error={!!newPasswordError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={verifyNewPassword}
                label="Verify new password"
                onChange={(e) => { setVerifyNewPassword(e.target.value); setVerifyNewPasswordError(false) }}
                variant="outlined"
                type="password"
                autoComplete="verify-new-password"
                // required
                fullWidth
                helperText={verifyNewPasswordError ? verifyNewPasswordError : ''}
                error={!!verifyNewPasswordError}
              />
            </Grid>
            {
              !updateSuccessful ? (
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={() => { handleSubmit() }} fullWidth>Update password</Button>
                </Grid>
              ) :
              (
                <Grid item xs={12}>
                  <Typography>Your password was successfully updated.</Typography>
                  <Button variant="contained" color="primary" onClick={() => { history.push(accountPath) }} fullWidth>View your account</Button>
                </Grid>
              )
            }
            <Grid item xs={5}>
              <Link href={forgotPasswordPath} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            {
              !updateSuccessful ? (
                <Grid item xs={7}>
                  <div className="update-password__view-account">
                    <Link href={accountPath} variant="body2">
                      View your account
                    </Link>
                  </div>
                </Grid>
              ) : ''
            }



            <Grid item xs={12}>
              {submissionError ? <FormLabel value={submissionError} error={true}>{submissionError}</FormLabel> : ''}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(UpdatePassword); 