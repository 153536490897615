import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import * as Sentry from '@sentry/react';
import { setInitialState, unsetInitialState } from './actions/setInitialState';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './helpers/theme';
import AppRouter, { history } from './routers/AppRouter';
import LoadingPage from './components/LoadingPage';
import { firebase } from './firebase/firebase';
import { updateClaims, statusIsTrialorPaidorTrialInactive } from './helpers/auth';
import { env, inDevx } from './private/private';
import { pricingPath, signinPath, signupPath, fallbackPath, welcomePath } from './helpers/links';
import { setTrialRoleActive } from './helpers/auth';
import { sentryUrl } from './private/private';

Sentry.init({
  dsn: sentryUrl,
  environment: env,
  server_name: 'client'
});


const store = configureStore();

const actualTemplate = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>
);

console.log(`Environment is: ${env} `)

// Render the template to the DOM
let hasRendered = false;
const output = document.getElementById('root');
const renderApp = () => {
  // console.log('hasRendered,', hasRendered);
  if (!hasRendered) {
    ReactDOM.render(actualTemplate, output)
    hasRendered = true;
    // console.log('hasRendered,', hasRendered);
  }
};

ReactDOM.render(<LoadingPage />, output)

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    // console.log('User is signed in:', user.uid);

    Sentry.configureScope(function (scope) {
      scope.setUser({ "id": user.uid});
    });

    let idTokenResult = await updateClaims(user);

    /* -- Handle signed in user -- */
    // 0. Set account roles
    let roles = {}
    // console.log('idTokenResult: ', idTokenResult);
    // idTokenResult = idTokenResult === null ? await user.getIdTokenResult() : idTokenResult;
    if (idTokenResult.claims.admin) {
      roles.admin = true;
    }
    if (idTokenResult.claims.superAdmin) {
      roles.superAdmin = true;
    }
    if (idTokenResult.claims.plans) {
      roles.plans = idTokenResult.claims.plans;
    }
    if (inDevx) {console.log('roles:', roles)};
    
    // If the user signed up for the first time, set up the trial account.
    let pageBeforeAuth = history.location.pathname;

    if (pageBeforeAuth === signupPath) {
      await setTrialRoleActive(user.uid).then( async () => {
        window.location.href = welcomePath.substring(1);
      }).catch((error) => {
        console.log({ error });
        history.push(fallbackPath);
      })
    } else {
      // 1. Set initial state
      await store.dispatch(setInitialState(user.uid, roles)).then(
        async () => {
          await renderApp();
        }
      );

      // 2. Redirect the user to the home page if signed in
      // If previous page was '/', go to '/'.
      if (pageBeforeAuth[pageBeforeAuth.length - 1] === '/') {
        pageBeforeAuth = pageBeforeAuth.substring(0, pageBeforeAuth.length - 1);
      }
      // if the previous page was 'signin' and the user was logged in, go to '/'.
      if (pageBeforeAuth === signinPath && statusIsTrialorPaidorTrialInactive(roles)) {
        history.push('/');

        // if the previous page was 'signin' and the user was not logged in, go to '/pricing'.
      } else if (pageBeforeAuth === signinPath && !statusIsTrialorPaidorTrialInactive(roles)) {
        history.push(pricingPath);

        // if the user is logging in for the first time or the previous page was '/' and the user has not paid, go to '/pricing';
      } else if ((pageBeforeAuth === '/' || pageBeforeAuth === '') && !statusIsTrialorPaidorTrialInactive(roles)) {
        history.push(pricingPath);

        // if the user had been at a previous page before signing in, go to that previous page.
      } else if (
        pageBeforeAuth.substring(0, 7) === signinPath &&
        pageBeforeAuth.length > 7
      ) {
        const path = pageBeforeAuth.substring(7);
        history.push(path);
      }
    }
  } else {
    // No user is signed in.
    console.log('No user is signed in');
    store.dispatch(unsetInitialState());
    renderApp();
    if (history.location.pathname === '/') {
      history.push('/');
    }
  }
});