import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Toolbar, AppBar, IconButton} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { messagesPath } from '../helpers/links'
import CopyButton from './CopyButton';
import { paper, appbar, white } from '../styles/styles.scss';
import AvatarMenu from './AvatarMenu';
import { connect } from 'react-redux';
import { history } from '../routers/AppRouter';
import SaveButtonGroup from './SaveButtonGroup';
import UndoRedoButtons from './UndoRedoButtons';
import AlternativesSwitch from './AlternativesSwitch';
import TemplateName from './TemplateName';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: paper,
  },
  appbar: {
    backgroundColor: appbar
  },
  appbarContainer: {
    backgroundColor: white,
    color: white
  }
}));


const Bar = ({auth, templateName, getStarted}) => {

  const classes = useStyles();

  return (
    <div className={classes.appbarContainer}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <div className="toolbar">
            <div className="toolbar__start-links">
              <div className="hide-when-xs">
                <Button
                  onClick={() => { history.push(messagesPath) }}
                  color="inherit"
                  startIcon={<ArrowBack />}
                >
                  <div>Back</div>
                </Button>
              </div>
              <div className="show-when-xs">
                <IconButton
                  size="small"
                  onClick={() => { history.push(messagesPath) }}
                  color="inherit"
                >
                  <ArrowBack />
              </IconButton>
              </div>
              <div className="hide-when-xs">
                <UndoRedoButtons />
              </div>
              <TemplateName templateName={templateName} />
            </div>  
            <div className="toolbar__end-links">
              <div className="toolbar__button">
                <AlternativesSwitch />
              </div>
              {getStarted ? (
                <div id="copy-button" className="toolbar__button">
                  <CopyButton />
                </div>
              ) : (
                <div className="toolbar__button">
                  <SaveButtonGroup />
                </div>
              )}
              <div>
                {!auth ? '' :
                  <div className="toolbar__button">
                    <AvatarMenu />
                  </div>
                }
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Bar);