import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { AccountCircle, Chat, Person, CancelPresentation, Help } from '@material-ui/icons';
import { history } from '../routers/AppRouter';
import { startSignout } from '../actions/auth';
import { connect } from 'react-redux';
import { contactPath, accountPath, getStartedPath } from '../helpers/links';


const AvatarMenu = ({dispatch, type}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleAccountMenu = (event) => {
    if (Boolean(anchorEl)) {
      setAnchorEl(false)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <div>
      <Tooltip title="Your account">
        <IconButton color="inherit" onClick={(e) => { toggleAccountMenu(e) }}>
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => toggleAccountMenu()} 
      >
        {/* {type === 'navbar' ? '' : <MenuItem onClick={() => { history.push(messagesPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><List /></span>Your messages</div></MenuItem>} */}
        {type === 'navbar' ? '' : <MenuItem onClick={() => { history.push(contactPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><Chat /></span>Contact us</div></MenuItem>}
        <MenuItem onClick={() => { history.push(accountPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><Person /></span>Account</div></MenuItem>
        <MenuItem onClick={() => { window.location.replace(getStartedPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><Help /></span>Product Tour</div></MenuItem>
        <MenuItem onClick={() => { dispatch(startSignout()) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><CancelPresentation /></span>Sign out</div></MenuItem>
      </Menu>
    </div>
  )

}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AvatarMenu);