import React from 'react';
import { Route } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { connect } from 'react-redux';


const NavRoute = ({
  auth,
  noFooter = false,
  component: Component,
  ...rest
}) => {

  return (
    <Route {...rest} component={(props) => {
      if (auth === 'loading') {
       return <LoadingPage />
      } else {
        return (
          <div className="page-container">
            <div>
              <Navbar />
              <Component {...props} />
            </div>
            {noFooter ? '' : <Footer />}
          </div>
        )
      }
    }} />
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(NavRoute);