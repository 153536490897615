import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import Navbar from '../components/Navbar';
import { connect } from 'react-redux';
import Footer from '../components/Footer';


const SignupRoute = ({
  auth,
  noFooter = false,
  roleMustBe,
  component: Component,
  ...rest
}) => {

  return (
    <Route {...rest} component={(props) => {
      if (auth === 'loading') {
        return <LoadingPage />
      } else if (!auth.uid) {
        return (
          <div className="page-container">
            <div>
              <Navbar />
              <Component {...props} />
            </div>
            {noFooter ? '' : <Footer />}
          </div>
        )
      } else {
        return <Redirect to='/' />
      }
    }} />
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(SignupRoute);