const activeSuggestionReducerDefaultState = false;

const activeSuggestionReducer = (
  state = activeSuggestionReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_ACTIVE_SUGGESTION':
      return action.suggestion
    case 'UNSET_ACTIVE_SUGGESTION':
      return false
    default:
      return state;
  }
}

export default activeSuggestionReducer;
