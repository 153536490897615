import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { history } from '../routers/AppRouter';
import { workspacePath } from '../helpers/links';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';

const OpenMessage = ({messageId, blank, auth, account}) => {

  const handleClick = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (messageId) { amplitude.logEvent('Open message', { messageId: messageId }) };
    }
    history.push(workspacePath(messageId));
  }

  return !blank ? (
    <Tooltip title="Open Message"><IconButton color="primary" onClick={handleClick}><Launch /></IconButton></Tooltip>
  ) : (
    <div className="open-message__blank"></div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(OpenMessage); 