import React from 'react';
import { connect } from 'react-redux';
import SuggestionRow from './SuggestionRow';
import { returnSuggestionsArray } from '../../helpers/categories';


const SuggestionsGrid = ({categories}) => {

  return (
    <div>
      {returnSuggestionsArray(categories)
      .sort((a, b) => (a[1] > b[1] ? 1 : -1))
      .map((entry) => {
        return (<SuggestionRow entry={entry} key={entry[2]} />)
      })}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(SuggestionsGrid);