const templatesAboutReducerDefaultState = {}

const templatesAboutReducer = (
  state = templatesAboutReducerDefaultState, action
) => {
  switch (action.type) {
    case 'INIT_TEMPLATES_ABOUT':
      return action.templatesAbout
    case 'UNSET_TEMPLATES_ABOUT':
      return templatesAboutReducerDefaultState   
    case 'SAVE_TEMPLATE_ABOUT':
      return {
        ...state,
        [action.templateId]: {
          ...state[action.templateId],
          ...action.updates
        }
      }
    case 'REMOVE_TEMPLATE_ABOUT':
      const res = { ...state };
      delete res[action.templateId];
      return res;
    default:
      return state;
  }
}

export default templatesAboutReducer;
