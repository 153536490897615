import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { termsOfUseUrl, privacyPolicyUrl } from '../helpers/links';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <div className="footer__text">
          <div className="footer__terms-line">
            <Typography variant="caption">
              © 2020 Burning Ice, Inc.
            </Typography>
          </div>
          <div className="footer__terms-line">
            <div className="footer__terms-element">
              <Typography variant="caption" >
                <Link href={termsOfUseUrl} >Terms of Use</Link>
              </Typography>
            </div>
            <div className="footer__terms-element">
              <Typography variant="caption" >
                <Link href={privacyPolicyUrl} >Privacy Policy</Link>
              </Typography>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}