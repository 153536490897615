import React from 'react';
import TechniqueBox from '../components/TechniqueBox';
import { connect } from 'react-redux';


const Editor = ({activeCategories, categories, hideEditor, categoryOrder}) => {

  let cats = Object.keys(activeCategories).length > 0 ? activeCategories : categories;

  return (
    <div id="editor" className="editor">
      {hideEditor ? '' : (
        <div>
          {
            !cats ? '' :
              Object.entries(cats).sort(
                (a, b) => {
                 if (categoryOrder[a[0]] && categoryOrder[b[0]]) {
                    return (categoryOrder[a[0]] > categoryOrder[b[0]]) ? 1 : -1
                  } else {
                    return -1;
                  }
                }
                ).map((category) => {
                  return <TechniqueBox
                    category={category}
                    categoryId={category[0]}
                    key={category[0]}
                    id={category[0]}
                  />
                })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories,
    activeCategories: state.activeCategories,
    expandedBox: state.expandedBox,
    hideEditor: state.hideEditor
  }
}

export default connect(mapStateToProps)(Editor);