import database from '../firebase/firebase';

export const retrieveTemplateContent = async (templateId) => {
  return await database.ref(`templatesContent/${templateId}`).once('value').then(
    async (snapshot) => {
      const temp = snapshot.val();
      return await temp === null || temp === undefined ? { subject: '', body: '', selectedCategories: {} } : await temp;
    }
  ).catch(
    (e) => { console.log('Error retrieving template from database: ', e) }
  );
}