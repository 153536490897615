import React, {useState} from 'react';
import { Grid, Button, TextField, Link, FormControlLabel, Switch } from '@material-ui/core';
import { validateEmail } from '../../helpers/formValidation';
import { updatePasswordPath, updateEmailPath } from '../../helpers/links';
import { startSaveAccount } from '../../actions/account';
import { connect } from 'react-redux';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';

const ProfileContent = ({dispatch, auth, account}) => {

  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  // const [company, setCompany] = useState(account.company ? account.company : '');
  const [email, setEmail] = useState(account.email);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  // const [companyError, setCompanyError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const toggleDisabled = () => {
    disabled ? setDisabled(false) : setDisabled(true);
  }
  

  const handleSubmit = () => {
    let errors = false;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (firstName.length === 0) {
      setFirstNameError(true);
    }
    if (lastName.length === 0) {
      setLastNameError(true);
    }
    // if (company.length === 0) {
    //   setCompanyError(true);
    // }
    if (!errors) {
      submit();
    }
  }

  const submit = () => {
    const updates = {
      uid: account.uid,
      firstName,
      lastName,
      email,
      // company,
    }
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (updates) { amplitude.logEvent('Update account', { updates }) };
    }
    dispatch(startSaveAccount(updates))
  }

  return (
    <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!disabled}
                    onChange={() => { toggleDisabled()} }
                    name="update-your-information"
                    color="primary"
                  />
                }
                label="Update your information"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={firstName}
                label="First name"
                onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={firstNameError ? 'Field cannot be empty' : ''}
                error={!!firstNameError}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={lastName}
                label="Last name"
                onChange={(e) => { setLastName(e.target.value); setLastNameError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={lastNameError ? 'Field cannot be empty' : ''}
                error={!!lastNameError}
                disabled={disabled}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                value={company}
                label="Company"
                onChange={(e) => { setCompany(e.target.value); setCompanyError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={companyError ? 'Field cannot be empty' : ''}
                error={!!companyError}
                disabled={disabled}
              />
            </Grid> */}
            {disabled ?
              <Grid item xs={12}>
 
                <TextField
                  value={email}
                  label="Email"
                  onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                  variant="outlined"
                  // required
                  fullWidth
                  helperText={emailError ? 'Please enter a valid email address' : ''}
                  error={!!emailError}
                  disabled={disabled}
                />
              </Grid> : ''}
            {!disabled ?
              <Grid item xs={12}>
                < Link href={updateEmailPath} variant="body2">
                  Update email
                </Link>
              </Grid> : ''
            }
            <Grid item xs={12}>
              <Link href={updatePasswordPath} variant="body2">
                Update password
              </Link>
            </Grid>
            {disabled ? '' : (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { handleSubmit() }}
                  fullWidth
                  disabled={disabled}
                >Save</Button>
              </Grid>
            )}
          </Grid>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(ProfileContent);