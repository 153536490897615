import React, { useState } from 'react';
import {TextField, Button} from '@material-ui/core';
import { firebase } from '../../firebase/firebase';

const SetAdmin = () => {

  const [email, setEmail] = useState('');

  const handleClick = async () => {
    const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
    await addAdminRole({email, }).then(() => {
      console.log(`${email} has been made an admin`);
    }).catch((error) => {
      console.log('Error: ', error);
    })
  }

  return ( 
    <div className="set-roles">
      <TextField
        value={email}
        onChange={(e) => {setEmail(e.target.value)}}
        variant="outlined"
        label="Email"
      />
      <Button variant="contained" color="primary" onClick={handleClick}>Make admin</Button>
    </div>
  )
}

export default SetAdmin;