const incrementChangesSinceSave = () => ({
  type: 'INCREMENT_CHANGES_SINCE_SAVE',
});

export const startIncrementChangesSinceSave = () => {
  return (dispatch) => {
    dispatch(incrementChangesSinceSave());
  }
}

const initChangesSinceSave = () => ({
  type: 'INIT_CHANGES_SINCE_SAVE',
});

export const startInitChangesSinceSave = () => {
  return (dispatch) => {
    dispatch(initChangesSinceSave());
  }
}