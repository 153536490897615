export default (state = {}, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return {
        uid: action.uid
      };
    case 'SIGNOUT':
      return {};
    case 'SIGNIN_WITH_ROLES':
      return {
        uid: action.uid,
        roles: action.roles
      }
    default:
      return state;
  }
};
