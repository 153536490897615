const accountReducerDefaultState = {
  firstName: '',
  lastName: '',
  email: '',
  // country: '',
  // company: '',
  uid: ''
}

const accountReducer = (
  state = accountReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        firstName: action.account.firstName,
        lastName: action.account.lastName,
        email: action.account.email,
        // country: action.account.country,
        // company: action.account.company ? action.account.company : '',
        uid: action.account.uid
      }
    case 'UNSET_ACCOUNT':
      return {...accountReducerDefaultState}
    default:
      return state;
  }
}

export default accountReducer;
