import React, { useState } from 'react';
import { TextField, Button, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { startSaveSuggestion } from '../../actions/categories';
import { replaceDumbText } from '../../helpers/editText';


const CreateSuggestion = ({ dispatch, categories }) => {

  const [categoryId, setCategoryId] = useState('');
  const [suggestionName, setSuggestionName] = useState('');
  const [note, setNote] = useState('');

  const handleClick = () => {
    const suggestionId = uuid();
    const suggestionContent = { suggestionName, note };
    dispatch(startSaveSuggestion(categoryId, suggestionId, suggestionContent));
    setSuggestionName('');
    setNote('');
  }

  return (
    <div>
      <div className="admin-create-suggestion">
        <div className="admin-text-medium">
          <FormControl fullWidth={true}>
            <InputLabel id="category-id-Label">Category</InputLabel>
            <Select
              labelId="selected-category-label"
              id="category"
              value={categoryId}
              onChange={(e) => { setCategoryId(e.target.value) }}
            >
              {Object.entries(categories)
              .sort((a, b) => (a[1].categoryName > b[1].categoryName ? 1 : -1))
              .map((entry) => {
                return <MenuItem value={entry[0]} key={entry[0]}>{entry[1].categoryName}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className="admin-text-large">
          <TextField label='suggestionName' id='suggestion-name' value={suggestionName} fullWidth={true} onChange={(e) => { setSuggestionName(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-large">
          <TextField label='note' id='note' value={note} fullWidth={true} onChange={(e) => { setNote(replaceDumbText(e.target.value)) }} />
        </div>
        <Button id='add-suggestion' variant="contained" color="primary" onClick={() => { handleClick() }}>Create Suggestion</Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(CreateSuggestion);