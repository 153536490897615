const templatesContentReducerDefaultState = {}

const templatesContentReducer = (
  state = templatesContentReducerDefaultState, action
) => {
  switch (action.type) {
    case 'INIT_TEMPLATES_CONTENT':
      return action.templatesContent
    case 'UNSET_TEMPLATES_CONTENT':
      return templatesContentReducerDefaultState
    case 'UPDATE_TEMPLATE_CATEGORIES_CONTENT':
      return {
        ...state,
        [action.templateId]: {
          ...state[action.templateId],
          selectedCategories: action.updates
        }
      }    
    case 'SAVE_TEMPLATE_CONTENT':
      return {
        ...state,
        [action.templateId]: {
          ...state[action.templateId],
          ...action.updates
        }
      }
    case 'REMOVE_TEMPLATE_CONTENT':
      const res = { ...state };
      delete res[action.templateId];
      return res;
    default:
      return state;
  }
}

export default templatesContentReducer;
