import { loadStripe } from '@stripe/stripe-js';


// Environment variables
export const env = process.env.REACT_APP_ENV
export const inProd = env === 'PROD';
export const inDevx = env === 'DEVX';
export const servedFrom = process.env.NODE_ENV === 'development' ? 'localhost' : 'server';
export const runningLocallyInDevx = inDevx && process.env.NODE_ENV === 'development';


// Firebase
export const firebaseConfiguration = {
  apiKey: process.env[`REACT_APP_FIREBASE_API_KEY_${env}`],
  authDomain: process.env[`REACT_APP_FIREBASE_AUTH_DOMAIN_${env}`],
  databaseURL: process.env[`REACT_APP_FIREBASE_DATABASE_URL_${env}`],
  projectId: process.env[`REACT_APP_FIREBASE_PROJECT_ID_${env}`],
  storageBucket: process.env[`REACT_APP_FIREBASE_STORAGE_BUCKET_${env}`],
  messagingSenderId: process.env[`REACT_APP_FIREBASE_MESSAGE_SENDER_ID_${env}`],
  appId: process.env[`REACT_APP_FIREBASE_APP_ID_${env}`],
  measurementId: process.env[`REACT_APP_FIREBASE_MEASUREMENT_ID_${env}`]
};


// Amplitude
export const AmplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;


// Stripe
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeApiKey = process.env[`REACT_APP_STRIPE_API_KEY_${env}`]
export const stripePromise = loadStripe(stripeApiKey);


// Formspree
export const formspreeUrl = 'https://formspree.io/xgennjpb';


export const sentryUrl = 'https://b9912b7361b54982b79adb19d82d1e31@o426466.ingest.sentry.io/5392628';