import React, {useState} from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import { firebase } from '../../firebase/firebase';
import { servedFrom, env } from '../../private/private';


const SaveProvidedData = () => {

  const [providedData, setProvidedData] = useState('');

  const handleClick = async () => {
    const saveProvidedData = firebase.functions().httpsCallable('saveProvidedData');
    const parsedJson = JSON.parse(providedData);
    if (!parsedJson) {
      console.log('Cannot save providedData because it is empty.')
    } else {
      return await saveProvidedData({ providedData: parsedJson }).then(() => {
        return console.log(`Provided data has been saved.`);
      }).catch((error) => {
        console.log('Error: ', error);
      });
    }
  }

  return (
    <div className="retrieve-provided-data">
      <Button variant="contained" color="primary" onClick={handleClick}>Save Provided Data</Button>
      <Typography gutterBottom color="secondary">
        {`Environment is ${env}.  Served from ${servedFrom}.`}
      </Typography>
      <TextField
        label="Provided Data"
        variant="outlined"
        multiline={true}
        fullWidth={true}
        value={providedData}
        onChange={(e) => {setProvidedData(e.target.value)}}
      >
      </TextField>
    </div>
  )
}

export default SaveProvidedData;