import { createMuiTheme } from '@material-ui/core/styles';
import { primary } from '../styles/styles.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary
    }
  },
  typography: {
    button: {
      textTransform: 'none',
      color: '#ffffff'
    }
  }
});

export default theme;