import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper} from '@material-ui/core';
import { connect } from 'react-redux';
import NewMessageButton from './NewMessageButton';
import OpenMessage from './OpenMessage';
import DeleteMessageButton from './DeleteMessageButton';
import LoadingPage from './LoadingPage';
import {formatDateForTable} from '../helpers/dateFormatting';


const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  newMessageButton: {
    display: 'flex',
    justifyContent: 'flexEnd'
  },
  title: {
    flex: '1 1 200px',
  },
});

const MessagesTable = ({ messages, templatesAbout }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState('true');
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortOn, setSortOn] = useState('lastSaved');

  const handleSort = async (sortOn) => {
    await setSortOn(sortOn);
    await toggleSortDirection();
  }

  const toggleSortDirection = () => {
    sortDirection === 'asc' ? setSortDirection('desc') : setSortDirection('asc');
  }

  let rows = Object.entries(messages).map((entry) => {
    return {
      messageId: entry[0] ? entry[0] : '',
      subject: entry[1].subject ? entry[1].subject : '',
      lastSaved: entry[1].lastSaved ? formatDateForTable(entry[1].lastSaved) : '',
      templateId: entry[1].templateId ? entry[1].templateId : ''
    }
  })

  rows = rows.map((row) => {
    return {
      ...row,
      templateName: row.templateId && templatesAbout[row.templateId] && templatesAbout[row.templateId].name ? templatesAbout[row.templateId].name : ''
    }
  })

  const sortRows = (rows, sortOn, sortDirection) => {
    if (sortDirection === 'asc') {
      const sortedRows = rows.sort((a, b) => (a[sortOn] > b[sortOn] ? 1 : -1 ));
      return sortedRows
    } else if (sortDirection === 'desc') {
      const sortedRows = rows.sort((a, b) => (a[sortOn] > b[sortOn] ? -1 : 1));
      return sortedRows
    }
  } 

  useEffect(() => {
    if (messages !== undefined && messages && Object.keys(messages).length > 0) {
      setIsLoading(false);
    }
  }, [messages])

  return isLoading ? <LoadingPage/> : (
    <TableContainer component={Paper}>
      <Toolbar>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Your messages
        </Typography>
        <div className={classes.newMessageButton}>
          <NewMessageButton />
        </div>
      </Toolbar>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortOn === 'subject'}
                direction={sortDirection}
                onClick={() => { handleSort('subject') }}              
              >
                <div className="messages-table__start-head">
                  <b>Subject</b>
                </div>
              </TableSortLabel>
            </TableCell>
            <TableCell align="left">
              <TableSortLabel
                active={sortOn === 'templateName'}
                direction={sortDirection}
                onClick={() => { handleSort('templateName') }}
              >
                <div>
                  <b>Type</b>
                </div>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={sortOn === 'lastSaved'}
                direction={sortDirection}
                onClick={() => { handleSort('lastSaved') }}              
              >
                <div className="messages-table__end-head">
                  <b>Last Saved</b>
                </div>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortRows(rows, sortOn, sortDirection).map((row) => (
            <TableRow key={row.messageId}>
              <TableCell component="th" scope="row">
                <OpenMessage messageId={row.messageId} /> {row.subject}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.templateName}
              </TableCell>
              <TableCell align="right">
                 {row.lastSaved} <DeleteMessageButton messageId={row.messageId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state, props) => {
  return {
    messages: state.messages,
    templatesAbout: state.templatesAbout
  }
}

export default connect(mapStateToProps)(MessagesTable); 