const setActiveSuggestion = (suggestion) => ({
  type: 'SET_ACTIVE_SUGGESTION',
  suggestion
});

export const startSetActiveSuggestion = ({suggestionId, index, innerTextLength, ancestor, text}) => {
  return (dispatch) => {
    dispatch(setActiveSuggestion({suggestionId, index, innerTextLength, ancestor, text}));
  }
}

const unsetActiveSuggestion = () => ({
  type: 'UNSET_ACTIVE_SUGGESTION',
});

export const startUnsetActiveSuggestion = () => {
  return (dispatch) => {
    dispatch(unsetActiveSuggestion());
  }
}