import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { Save, Delete } from '@material-ui/icons';
import { startSaveTemplateAbout, startRemoveTemplateAbout } from '../../actions/templatesAbout';
import { startSaveTemplateContent, startRemoveTemplateContent } from '../../actions/templatesContent';
import { connect } from 'react-redux';
import { replaceDumbText } from '../../helpers/editText';


const TemplateRow = ({ dispatch, entry, templatesContent }) => {

  const templateId = entry[0];
  const [name, setName] = useState(entry[1].name ? entry[1].name : '')
  const [description, setDescription] = useState(entry[1].description ? entry[1].description : '')
  const [useWith, setUseWith] = useState(entry[1].useWith ? entry[1].useWith : '')
  const [status, setStatus] = useState(entry[1].status ? entry[1].status : '')
  const [icon, setIcon] = useState(entry[1].icon ? entry[1].icon : '')
  const [order, setOrder] = useState(entry[1].order ? entry[1].order : 0)
  const [subject, setSubject] = useState(templatesContent[entry[0]] && templatesContent[entry[0]].subject ? templatesContent[entry[0]].subject : '')
  const [body, setBody] = useState(templatesContent[entry[0]] && templatesContent[entry[0]].body ? templatesContent[entry[0]].body : '')
  const [confirm, setConfirm] = useState('')
  const [selectedCategories, setSelectedCategories] = useState({});
  useEffect(() => {
    if (templatesContent && templatesContent[templateId] && templatesContent[templateId].selectedCategories) {
      setSelectedCategories(templatesContent[templateId].selectedCategories);
    }
  }, [templatesContent, templateId])

  const handleSave = () => {
    const about = { name, description, useWith, status, icon, order };
    dispatch(startSaveTemplateAbout(templateId, about));
    const content = { subject, body, selectedCategories };
    dispatch(startSaveTemplateContent(templateId, content));
    console.log('handleSave');
  }

  const handleDelete = () => {
    if (confirm === 'yes') {
      dispatch(startRemoveTemplateAbout(templateId));
      dispatch(startRemoveTemplateContent(templateId));
    } else {
      console.log('You must confirm deletion before deleting');
    }
    console.log('handleDelete');
  }



  return (
    <div className="admin-category-row">
      <div className="admin-text-small">
        <TextField id={entry[0]} label='tempId' fullWidth={true} value={templateId} />
      </div>
      <div className="admin-text-medium">
        <TextField id={entry[1].name} label='name' fullWidth={true} value={name} onChange={(e) => { setName(replaceDumbText(e.target.value)) }} />
      </div>
      <div className="admin-text-medium">
        <TextField id={entry[1].description} label='description' fullWidth={true} value={description} onChange={(e) => { setDescription(replaceDumbText(e.target.value)) }} />
      </div>
      <div className="admin-text-medium">
        <TextField id={entry[1].useWith} label='useWith' fullWidth={true} value={useWith} onChange={(e) => { setUseWith(e.target.value) }} />
      </div>
      <div className="admin-text-small">
        <TextField id={entry[1].status} label='status' fullWidth={true} value={status} onChange={(e) => { setStatus(e.target.value) }} />
      </div>
      <div className="admin-text-small">
        <TextField id={entry[1].icon} label='icon' fullWidth={true} value={icon} onChange={(e) => { setIcon(e.target.value) }} />
      </div>
      <div className="admin-text-small">
        <TextField id={entry[1].order} label='order' fullWidth={true} value={order} onChange={(e) => { setOrder(e.target.value) }} />
      </div>
      <div className="admin-text-small">
        <TextField label='selectedCategories' fullWidth={true} value={Object.keys(selectedCategories).length} />
      </div>
      <div className="admin-text-small">
        <textarea label='subject' value={subject} onChange={(e) => { setSubject(replaceDumbText(e.target.value)) }} />
      </div>
      <div className="admin-text-small">
        <textarea label='body' value={body} onChange={(e) => { setBody(replaceDumbText(e.target.value)) }} />
      </div>
      <div className="admin-text-small">
        <TextField id='confirm' label='yes' fullWidth={true} value={confirm} onChange={(e) => { setConfirm(e.target.value) }} />
      </div>
      <div>
        <IconButton id='save-template' variant="contained" onClick={() => { handleSave() }}><Save /></IconButton>
        <IconButton id='delete-template' variant="contained" onClick={() => { handleDelete() }}><Delete /></IconButton>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    templatesContent: state.templatesContent,
    templatesAbout: state.templatesAbout
  }
}

export default connect(mapStateToProps)(TemplateRow);




