import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { startSaveTemplateAbout } from '../../actions/templatesAbout';
import { startSaveTemplateContent } from '../../actions/templatesContent';
import { replaceDumbText } from '../../helpers/editText';


const CreateTemplate = ({ dispatch, templatesAbout }) => {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [useWith, setUseWith] = useState('');
  const [status, setStatus] = useState('');
  const [icon, setIcon] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [order, setOrder] = useState(templatesAbout ? Object.keys(templatesAbout).length + 1 : 0);


  const handleClick = () => {
    const templateId = uuid();
    const about = { name, description, useWith, status, icon, order };
    dispatch(startSaveTemplateAbout(templateId, about));
    // const categories = [];
    const selectedCategories = {};
    const content = { subject, body, selectedCategories };
    dispatch(startSaveTemplateContent(templateId, content));

  }

  return (
    <div>
      <div className="admin-create-category">
        <div className="admin-text-medium">
          <TextField label='templateName' id='template-name' fullWidth={true} onChange={(e) => { setName(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-medium">
          <TextField label='description' id='description' fullWidth={true} onChange={(e) => { setDescription(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-medium">
          <TextField label='useWith' id='useWith' fullWidth={true} onChange={(e) => { setUseWith(e.target.value) }} />
        </div>
        <div className="admin-text-small">
          <TextField label='status' id='status' fullWidth={true} onChange={(e) => { setStatus(e.target.value) }} />
        </div>
        <div className="admin-text-small">
          <TextField label='icon' id='icon' fullWidth={true} onChange={(e) => { setIcon(e.target.value) }} />
        </div>
        <div className="admin-text-small">
          <TextField label='order' id='order' fullWidth={true} onChange={(e) => { setOrder(e.target.value) }} />
        </div>
        <div className="admin-text-small">
          <TextField label='subject' id='subject' fullWidth={true} onChange={(e) => { setSubject(replaceDumbText(e.target.value)) }} />
        </div>
        <div className="admin-text-small">
          <TextField label='body' id='body' fullWidth={true} onChange={(e) => { setBody(replaceDumbText(e.target.value)) }} />
        </div>
        <div>
          <Button id='create-template' variant="contained" color="primary" onClick={() => { handleClick() }}>Create Template</Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    templatesAbout: state.templatesAbout
  }
}

export default connect(mapStateToProps)(CreateTemplate);
