const messageLastUpdatedDefaultState = false;

const messageLastUpdatedReducer = (
  state = messageLastUpdatedDefaultState, action
) => {
  switch (action.type) {
    case 'SET_MESSAGE_LAST_UPDATED':
      return action.time
    default:
      return state;
  }
}

export default messageLastUpdatedReducer;
