import React, {useState} from 'react';
import { Grid, Typography, Button, CardContent, Card, TextField, FormLabel } from '@material-ui/core';
import { validateEmail } from '../../helpers/formValidation';
import { firebase } from '../../firebase/firebase';
import { history } from '../../routers/AppRouter';
import { connect } from 'react-redux';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';


const ForgotPasswordPage = ({auth, account}) => {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  
  const handleSubmit = () => {
    let errors = false;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (!errors) {
      submit()
    }
  }

  const submit = () => {
    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      if (email) { amplitude.logEvent('Submit Forget Password', { email: email }) };
    }
    firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
      setEmailSent(true);
      console.log('Reset email sent');
    }).catch((error) => {
      var errorCode = error.code;
      if (errorCode === 'auth/invalid-email') {
        setSubmissionError('Email address is not valid');
        console.log(error);
      } else if (errorCode === 'auth/user-not-found') {
        setSubmissionError('There is no user corresponding to this email.');
        console.log(error);
      } else {
        console.log(error);
        console.log(submissionError);
      }
    });
  }


  return (
    <div className="get-started">
      <Card className="intro-card">
          {!emailSent ? (
            <CardContent>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Forgot your password?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Please enter your email address. You will receive a link to create a new password via email.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  label="Email"
                  onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                  variant="outlined"
                  // required
                  fullWidth
                  helperText={emailError ? 'Please enter a valid email address' : ''}
                  error={!!emailError}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => { handleSubmit() }} fullWidth>Reset password</Button>
              </Grid>
              <Grid item xs={12}>
                <FormLabel value={submissionError} error>{submissionError}</FormLabel>
              </Grid>
            </Grid>
          </CardContent>
          ) : (
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    A password reset link has been sent to your email.  Please check your inbox.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={() => { history.push('/') }} fullWidth>Back to home</Button>
                </Grid>
              </Grid>
            </CardContent>

          )}
      </Card>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(ForgotPasswordPage); 