import React, {useState} from 'react';
import { Grid, Typography, Button, CardContent, Card, TextField, Link, FormLabel } from '@material-ui/core';
import { validateEmail, isPasswordError } from '../../helpers/formValidation';
import { signupPath, forgotPasswordPath } from '../../helpers/links';
import { firebase } from '../../firebase/firebase';


export default () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  
  const handleSubmit = () => {
    let errors = false;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (isPasswordError(password)) {
      setPasswordError(isPasswordError(password));
      errors = true;
    }
    if (!errors) {
      submit()
    }
  }

  const submit = () => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .catch((error) => {
        var errorCode = error.code;
        if (error) {
          if (errorCode === 'auth/invalid-email') {
            setSubmissionError('Email address is not valid');
            console.log(error);
          } else if (errorCode === 'auth/user-disabled') {
            setSubmissionError('This account has been disabled.');
            console.log(error);
          } else if (errorCode === 'auth/user-not-found') {
            setSubmissionError('There is no user corresponding to this email.');
            console.log(error);
          } else if (errorCode === 'auth/wrong-password') {
            setSubmissionError('Invalid password');
            console.log(error);
          } else if (errorCode === 'auth/operation-not-allowed') {
            setSubmissionError(`We're sorry.  There was a problem.`);
            console.log(error);
          }        }
        console.log(error);
        console.log(submissionError);
      });
  }

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Sign in
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={email}
                label="Email"
                onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={emailError ? 'Please enter a valid email address' : ''}
                error={!!emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                label="Password"
                onChange={(e) => { setPassword(e.target.value); setPasswordError(false) }}
                variant="outlined"
                type="password"
                autoComplete="current-password"
                // required
                fullWidth
                helperText={passwordError ? passwordError : ''}
                error={!!passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => {handleSubmit() }} fullWidth>Sign in</Button>
            </Grid>
            <Grid item xs={12}>
              <FormLabel value={submissionError} error>{submissionError}</FormLabel>

            </Grid>
            <Grid item xs={5}>
              <Link href={forgotPasswordPath} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={7}>
              <Link href={signupPath} variant="body2">
                Don't have an account?  Sign up
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}