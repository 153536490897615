const categoriesReducerDefaultState = {}

const categoriesReducer = (
  state = categoriesReducerDefaultState, action
) => {
  switch (action.type) {
    case 'INIT_CATEGORIES':
      return action.categories
    case 'SET_CATEGORIES':
      return action.categories
    case 'UNSET_CATEGORIES':
      return categoriesReducerDefaultState
    case 'SAVE_CATEGORY':
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          ...action.updates
        }
      }
    case 'REMOVE_CATEGORY':
      const res = { ...state };
      delete res[action.categoryId];
      return res;
    case 'SAVE_SUGGESTION':
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          suggestions: {
            ...state[action.categoryId].suggestions,
            [action.suggestionId]: {
              ...state[action.categoryId].suggestions[action.suggestionId],
              ...action.updates
            }
          }
        }
      }
    case 'REMOVE_SUGGESTION':
      const re = { ...state };
      delete re[action.categoryId].suggestions[action.suggestionId];
      return re;
    default:
      return state;
  }
  
}

export default categoriesReducer;
