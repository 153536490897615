import React, { useState, useEffect } from 'react';
import CreateCategory from './CreateCategory';
import CategoriesGrid from './CategoriesGrid';
import ShowState from './ShowState';
import LoadingPage from '../LoadingPage';
import TemplatesGrid from './TemplatesGrid';
import CreateTemplate from './CreateTemplate';
import SuggestionsGrid from './SuggestionsGrid'
import CreateSuggestion from './CreateSuggestion';
import { connect } from 'react-redux';
import { startInitTemplatesAbout, startUnsetTemplatesAbout } from '../../actions/templatesAbout';
import { startInitTemplatesContent, startUnsetTemplatesContent } from '../../actions/templatesContent';
import { startInitCategories, startUnsetCategories } from '../../actions/categories';
import TemplateCategories from './TemplateCategories';
import { returnSuggestionsArray } from '../../helpers/categories';
import CategoryUsage from './CategoryUsage';


const Admin = ({dispatch, categories, templatesAbout}) => {  

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      const fetchData = async () => {
        await dispatch(startInitCategories());
        await dispatch(startInitTemplatesAbout());
        await dispatch(startInitTemplatesContent());
        setIsLoading(false);
      }
      fetchData();
    }
  }, [dispatch, isLoading])

  useEffect(() => {
    return () => {
      dispatch(startUnsetCategories());
      dispatch(startUnsetTemplatesAbout());
      dispatch(startUnsetTemplatesContent());
    }
  }, [dispatch])
  
  
  return isLoading ? <LoadingPage /> : (
    <div>
      <div className="admin__categories">
        <h2>Categories</h2>
        <div>{Object.keys(categories).length} categories</div>
        <CreateCategory/>
        <CategoriesGrid/>
      </div>
      <div className="admin__suggestions">
        <h2>Suggestions</h2>
        <div>{returnSuggestionsArray(categories).length} suggestions</div>
        <CreateSuggestion/>
        <SuggestionsGrid/>
      </div>
      <div>
        <h2>Templates</h2>
        <div>{Object.keys(templatesAbout).length} templates</div>
        <CreateTemplate/> 
        <TemplatesGrid/>
        <TemplateCategories/>
        <CategoryUsage />
      </div>
      <ShowState/>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: state.categories,
    templatesAbout: state.templatesAbout,
    templatesContent: state.templatesContent
  }
}

export default connect(mapStateToProps)(Admin);