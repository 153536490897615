import React from 'react';

export default () => (
  <div className="loading-page__container">
    <div className="loading-page__spinner">
      <img className="loading-page__loader-image" src="/images/loader-white.gif" alt="loading spinner" />
    </div>
  </div>
)

