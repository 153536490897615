const highlightModeReducerDefaultState = 'off'

const highlightModeReducer = (
  state = highlightModeReducerDefaultState, action
) => {
  switch (action.type) {
    case 'SET_HIGHLIGHT_MODE':
      return action.mode
    default:
      return state;
  }
}

export default highlightModeReducer;
