import database from '../firebase/firebase';

const initTemplatesAbout = (templatesAbout) => ({
  type: 'INIT_TEMPLATES_ABOUT',
  templatesAbout
});

export const startInitTemplatesAbout = () => {
  return (dispatch) => {
    return database.ref(`templatesAbout`).once('value').then(
      async (snapshot) => {
        let templatesAbout = snapshot.val();
        if (templatesAbout === null || templatesAbout === undefined) {templatesAbout = {}}
        await dispatch(initTemplatesAbout(templatesAbout));
      }
    ).catch(
      (e) => { console.log('Error retrieving templatesAbout from database: ', e) }
    );
  };
};

const unsetTemplatesAbout = () => ({
  type: 'UNSET_TEMPLATES_ABOUT',
});

export const startUnsetTemplatesAbout = () => {
  return (dispatch) => {
    dispatch(unsetTemplatesAbout());
  };
};

const saveTemplateAbout = (templateId, updates) => ({
  type: 'SAVE_TEMPLATE_ABOUT',
  templateId,
  updates
});

export const startSaveTemplateAbout = (templateId, about ) => {
  return (dispatch) => {
    database.ref(`templatesAbout/${templateId}`).update(about).then(() => {
      dispatch(saveTemplateAbout(templateId, about))
    }).catch(
      (e) => { console.log('Error saving templateAbout: ', e) }
    )
  }
}

const removeTemplateAbout = (templateId) => ({
  type: 'REMOVE_TEMPLATE_ABOUT',
  templateId
});

export const startRemoveTemplateAbout = (templateId) => {
  return (dispatch) => {
    database.ref(`templatesAbout/${templateId}`).remove().then(() => {
      dispatch(removeTemplateAbout(templateId));
    }).catch(
      (e) => { console.log('Error removing templateAbout: ', e) }
    );
  }
}