import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Person as PersonIcon, CreditCard as CreditCardIcon, Description as DescriptionIcon} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ProfileContent from './ProfileContent';
import BillingContent from './BillingContent';
import LegalContent from './LegalContent';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AccountPageContainer = () => {
  const classes = useStyles();

  const [expandedBox, setExpandedBox] = useState(false);
  const changeExpansion = (box) => {
    if (box === false) {
      setExpandedBox(false);
    } else if (expandedBox === box) {
      setExpandedBox(false);
    } else {
      setExpandedBox(box);
    }
  };


  return (
    <div className="get-started">
      <div className="choose-templates-card">

        {/* Title */}
        <div className="choose-templates-card__title">
          <div id="panel1d-header">
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Your account
            </Typography>
          </div>
        </div>

        {/* Panel */}
        <Accordion expanded={expandedBox === 'profile'} onChange={() => { changeExpansion('profile') }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.heading}><PersonIcon/></div>
            <Typography className={classes.secondaryHeading} color="textSecondary">Profile</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProfileContent/>
          </AccordionDetails>
        </Accordion>

        {/* Billing */}
        <Accordion expanded={expandedBox === 'billing'} onChange={() => { changeExpansion('billing') }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.heading}><CreditCardIcon /></div>
            <Typography className={classes.secondaryHeading} color="textSecondary">Subscription</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BillingContent />
          </AccordionDetails>
        </Accordion>

        {/* Billing */}
        <Accordion expanded={expandedBox === 'legal'} onChange={() => { changeExpansion('legal') }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.heading}><DescriptionIcon /></div>
            <Typography className={classes.secondaryHeading} color="textSecondary">Legal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LegalContent />
          </AccordionDetails>
        </Accordion>

      </div>
    </div>
  )
}

export default AccountPageContainer;