import React, { useState, useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import ContentTip from './ContentTip';
import { history } from '../routers/AppRouter';
import {pricingPath, messagesPath} from '../helpers/links';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import { startSetHideEditor } from "../actions/hideEditor";
import { startSetMessageContent } from "../actions/message";
import { statusIsTrialorPaidorTrialInactive } from '../helpers/auth';


const ProductTour = ({dispatch, expandedBox, highlightMode, message, copySnackbar, auth, account}) => {

  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(true);
  console.log('stepIndex', stepIndex, 'run', run);
  

  const editedMessage = {
    subject: `Your 20% Discount on Summer Sox Expires TONIGHT`,
    body: `If you have ever placed an order with us, or even if you’re just a fan, we’re so grateful for you!

We’re running a super rare promotion right now…

For the next 24 hours, you can get 40% off Summer Sox using code “SUMMER”!

Summer Sox have been flying off the shelf since the sale started, but plenty of our most popular items are still in stock.

Here are just a few of the popular items that you can still get:
- Water-proof beach socks
- Blister-free running socks
- Knee-high padded football socks

We wouldn’t wait too long if we were you.  We’re expecting everything to be sold out within the next day or two.

We can’t wait to ship your order and hear how much you liked everything!

Don’t wait,

-The Summer Sox Team
`
  };

  // Send data to amplitude.
  useEffect(() => {
    if (stepIndex === 0 && amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      amplitude.logEvent('Begin getstarted tour');
    }
  }, [stepIndex, auth, account])

  // Step 1: 
  // useEffect(() => {
  //   if (stepIndex === 0 && highlightMode === 'off') {
  //     console.log('update highlights');
  //     turnOnHighlights(categories, highlightMode);
  //     dispatch(startSetHighlightMode('highlight'));
  //   }
  // }, [dispatch, categories, highlightMode, stepIndex])


  // When the user clicks "Alternatives", unhide the editor and increment step
  useEffect(() => {
    if (stepIndex === 1 && highlightMode === 'alternatives') { 
      dispatch(startSetHideEditor(false))
      setStepIndex(stepIndex + 1);
    }
  }, [highlightMode, dispatch, setStepIndex, stepIndex]);

  // When the user clicks a highlightButton, increment step
  useEffect(() => {
    if (stepIndex === 2 && !!expandedBox) {
      setStepIndex(stepIndex + 1);
     }
  }, [expandedBox, setStepIndex, stepIndex]);

  useEffect(() => {
    if (stepIndex === 5) { 
      setStepIndex(stepIndex + 1);
     }
  }, [message]);

  useEffect(() => {
    if (stepIndex === 9 && message.subject !== editedMessage.subject && message.body !== editedMessage.body) {
      console.log('Step');
      dispatch(startSetMessageContent({
        body: editedMessage.body,
        subject: editedMessage.subject
    })) }
  }, [stepIndex, dispatch, message, editedMessage]);

  useEffect(() => {
    if (stepIndex === 10) { 
      setStepIndex(stepIndex + 1);
     }
  }, [copySnackbar]);


  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  }
  
  const steps = [
    {
      target: '#notepad-paper',
      content: {
        body: `Start with a pre-written message designed for your scenario.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'left-start',
      disableBeacon: true
    },
    {
      target: '#alternatives-switch',
      content: {
        body: `It's easy to customize this message.  Click "Customize". (↑)`,
        next: false,
        back: false,
        close: false
      },
      placement: 'bottom-end',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#notepad',
      content: {
        body: `SplashPad intelligently recommends alternative sentences that fit this message.  Click one of the sentences in blue to see alternatives. (→)`,
        next: false,
        back: false,
        close: false
      },
      placement: 'left-start',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#editor',
      content: {
        body: `The sentence highlighted in blue is the one that you just clicked. Above it, you can see what psychological technique that sentence uses in order to persuade your readers.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '#editor',
      content: {
        body: `You can also see alternative sentences intelligently recommended by SplashPad.  They help you grab attention, create social proof, build anticipation, and more.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '#editor',
      content: {
        body: `Click on one of the recommended sentences to add it to your message. (←)`,
        next: false,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#notepad',
      content: {
        body: `The language you clicked was added to your message.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '#notepad',
      content: {
        body: `You can add as many of these recommended sentences as you want.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#notepad',
      content: {
        body: `When you use the full version of SplashPad, you will be able edit the message free-form.  Let’s see what this message will look like once you customize it for your business, Summer Sox.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '#notepad',
      content: {
        body: `Now the message has been customized to promote your business, Summer Sox.`,
        next: true,
        back: false,
        close: false
      },
      placement: 'right',
      disableBeacon: true,
      spotlightClicks: false
    },
    {
      target: '#copy-button',
      content: {
        body: `Now you’re ready to send this message to your audience.  Click above to copy your message. (↑)`,
        next: false,
        back: false,
        close: false
      },
      placement: 'bottom',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#workspace',
      content: {
        body: `You can paste your message into an email or any other place you choose.  For example, paste your message in this box and click “Next”.`,
        next: true,
        back: false,
        close: false,
        textarea: true
      },
      placement: 'center',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#workspace',
      content: {
        body: !statusIsTrialorPaidorTrialInactive(auth.roles) ? (
          `Congratulations!  You have used SplashPad to write a strong promotional message!  For a limited time, get SplashPad for a reduced price.`
        ) : (
          'Congratulations!  You have used SplashPad to write a strong promotional message!'
        ),
        next: false,
        back: false,
        final: !statusIsTrialorPaidorTrialInactive(auth.roles) ? 'Click here to view your offer!' : 'Write more!',
        finalFunction: () => { history.push(!statusIsTrialorPaidorTrialInactive(auth.roles) ? pricingPath : messagesPath)}
      },
      placement: 'center',
      disableBeacon: true,
      spotlightClicks: true
    },
  ]

  return (
    <Joyride
      steps={steps}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      hideBackButton={true}
      showProgress={true}
      callback={handleJoyrideCallback}
      run={run}
      stepIndex={stepIndex}
      tooltipComponent={ContentTip}
      continuous={true}
      spotlightClicks={false}
    />
  )

}

const mapStateToProps = (state, props) => {
  return {
    highlightMode: state.highlightMode,
    expandedBox: state.expandedBox,
    message: state.message.present,
    copySnackbar: state.copySnackbar,
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(ProductTour);