import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core'
import { returnTemplateNameLength } from '../helpers/sizing';

const useStyles = makeStyles({
  typography: {
    fontSize: 14,
    margin: 8
  }
});

const TemplateName = ({templateName}) => {

  const classes = useStyles();
  
  const [templateNameLength, setTemplateNameLength] = useState(0);

  const resetTemplateNameLength = () => {
    setTemplateNameLength(returnTemplateNameLength());
  }

  useEffect(() => {
    resetTemplateNameLength();
  }, [])

  window.onresize = resetTemplateNameLength;

  const formatTemplateName = () => {
    if (templateName === '' || !templateName) {
      return '';
    } else if (templateName.length > templateNameLength) {
      return `${templateName.substring(0, templateNameLength - 4)}...`
    } else {
      return templateName;
    }
  }

  return !templateName || !templateNameLength || templateNameLength === 0 ? '' : (
    <Box borderRadius={4} border={1}>
      <Typography className={classes.typography}>{formatTemplateName()}</Typography>
    </Box>

  )
}

export default TemplateName;