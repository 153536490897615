import React, {useEffect, useState} from 'react';
import { TextField } from '@material-ui/core';
import {returnTextAreaRows, changeTextAreaOnResize} from '../helpers/sizing';
import { startSetMessageBody } from '../actions/message';
import { connect } from 'react-redux';
import { startIncrementChangesSinceSave } from '../actions/changesSinceSave';
import { useDebouncedCallback } from 'use-debounce';
import { triggerInputEvent, initJqueryHighlightPluginToNone } from '../helpers/highlightText';
import { startUnsetActiveSuggestion } from '../actions/activeSuggestion';
import { startSetMessageLastUpdated } from '../actions/messageLastUpdated';


const Body = ({ dispatch, body, activeSuggestion, highlightMode, messageLastUpdated, messageLastModified}) => {

  useEffect(() => {
    changeTextAreaOnResize();
  }, [])


  // The value shown in the text area
  const [text, setText] = useState(body)

  useEffect(() => {
    setText(body);
  }, [body])

  const handleChange = async (text) => {

    if (messageLastUpdated) {
      dispatch(startSetMessageLastUpdated(false));
      const myField = document.getElementById('body-text-field');
      const index = myField.selectionStart;
      await initJqueryHighlightPluginToNone();
      myField.focus();
      setTimeout(() => { myField.setSelectionRange(index, index) }, 10);
    } else if (activeSuggestion) {
      const myField = document.getElementById('body-text-field');
      const index = myField.selectionStart;
      await initJqueryHighlightPluginToNone();
      dispatch(startUnsetActiveSuggestion())
      // Next 2 lines are because jQuery plugin causes the field to unfocus.
      // After 10 milliseconds, re-focus the cursor where I was befor.
      // Clean this code up later.
      myField.focus();
      setTimeout(() => {myField.setSelectionRange(index, index)}, 10);
    }

    setText(text);
    updateStore(text);
  }

  // Debounce (delay) the update to the store
  const [updateStore] = useDebouncedCallback(
    (text) => {
      // If the messageContent has not been updated within the past 1,000 ms, update messageBody.
      if (messageLastModified < Date.now() - 1000) {
        dispatch(startSetMessageBody(text));
        dispatch(startIncrementChangesSinceSave());
      }
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    if ((highlightMode === 'highlight' || highlightMode === 'alternatives') && body !== text) {
      const update = async () => {
        await setText(body);
        triggerInputEvent('body-text-field');
      }
      update();
    }
  }, [body, text, highlightMode])


  return (
    <div className="body">
      <div className="hwt-container-0" id="body-text-field-container">
        <TextField
          multiline={true}
          fullWidth={true}
          rows={returnTextAreaRows()}
          variant="outlined"
          id="body-text-field"
          value={text}
          onChange={(e) => {handleChange(e.target.value)}}
          className="hwt-input"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    body: state.message.present.body,
    messageLastModified: state.message.present.time,
    activeSuggestion: state.activeSuggestion,
    highlightMode: state.highlightMode,
    messageLastUpdated: state.messageLastUpdated,
    
  }
}

export default connect(mapStateToProps)(Body);