export const domain = `https://getsplashpad.com/`;

export const contactUrl = `${domain}contact`

export const privacyPolicyUrl = `${domain}privacy`;

export const termsOfUseUrl = `${domain}terms`;

export const getStartedWorkspacePath = (templateId) => { return `/getstarted/workspace/${templateId}` }

export const workspacePath = (messageId) => { return `/workspace/${messageId}` }

export const signinPath = `/signin`;

export const signupPath = `/signup`;

export const forgotPasswordPath = '/forgotPassword';

export const updatePasswordPath = '/updatePassword';

export const updateEmailPath = '/updateEmail';

export const pricingPath = '/pricing';

export const accountPath = `/account`;

export const chooseTemplatesPath = (getstarted) => {
  return !getstarted === '/getStarted' || getstarted === undefined ? `/choosetemplate` : `/getstarted/choosetemplate`
}

export const messagesPath = `/messages`;

export const deleteMessagePath = (messageId) => `/deleteMessage/${messageId}`

export const contactPath = '/contact';

export const viewScenarioPath = `/getstarted/viewscenario`;

export const getStartedPath = `/getstarted`;

export const fallbackPath = `/fallback`;

export const billingPath = `/account/billing`;

export const cancelSubscriptionPath = `/account/cancelsubscription`;

export const messageNotAvailablePath = `/messagenotavailable`;

export const rootPath = '/';

export const welcomePath = '/welcome';