import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Menu as MenuIcon, AccessibilityNew } from '@material-ui/icons';
import { history } from '../routers/AppRouter';
import { connect } from 'react-redux';
import { contactPath, messagesPath, pricingPath } from '../helpers/links';
import { statusIsTrialorPaidorTrialInactive } from '../helpers/auth';


const SmallNavbarDropdown = ({auth}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleMenu = (event) => {
    if (Boolean(anchorEl)) {
      setAnchorEl(false)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  if (!auth.uid) {
    return ''
  } else {
    return (
      <div>
        <Tooltip title="Your account">
          <IconButton color="inherit" onClick={(e) => { toggleMenu(e) }}>
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={() => toggleMenu()}
        >
          {auth.uid ? (<MenuItem onClick={() => { history.push(messagesPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"></span>Your messages</div></MenuItem>) : ''}
          {statusIsTrialorPaidorTrialInactive(auth.roles) ? (<MenuItem onClick={() => { history.push(contactPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"></span>Contact us</div></MenuItem>) : ''}
          {auth.uid && !statusIsTrialorPaidorTrialInactive(auth.roles) ? (<MenuItem onClick={() => { history.push(pricingPath) }}><div className="avatar-menu__item"><span className="avatar-menu__icon"><AccessibilityNew /></span>Subscribe!</div></MenuItem>) : ''}
        </Menu>
      </div>
    )
  }

}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(SmallNavbarDropdown);