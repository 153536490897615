import { firebase } from '../firebase/firebase';
import { fallbackPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';


export const updateClaims = async (user) => {
  /* -- Update to latest custom claims -- */
  // For help: https://firebase.google.com/docs/auth/admin/custom-claims


  // 1. Check if refresh is required.
  const refreshIndicator = await firebase.database().ref(`users/${user.uid}/refresh`).once('value')
    .then((shapshot) => { return shapshot.val() })
    .catch((error) => {
      console.log('Could not retrieve refresh indicator from db: ', error);
      // REPORT ERROR
      history.push(fallbackPath);
    });
  const refreshRequired = refreshIndicator !== true || refreshIndicator === null ? false : true;
  // console.log('refreshRequired: ', refreshRequired);


  // 2. If no refresh is required, return idTokenResult
  if (!refreshRequired) {
    return await user.getIdTokenResult();


    // 3A. If refresh is required, first refresh the token.
  } else {
    const idTokenResult = await user.getIdTokenResult(true);
    // console.log('idTokenResult before refresh: ', idTokenResult);


    // 3B. Then update the refresh indicator in the database.
    await firebase.database().ref(`users/${user.uid}/refresh`).remove().catch((error) => {
      console.log('Unable to delete refresh indicator from database: ', error);
      // REPORT ERROR
      history.push(fallbackPath);
    });

    if (amplitudeFlag) {
      let roles;
      if (idTokenResult.claims) {
        roles = idTokenResult.claims;
      }
      if (user.uid) { amplitude.setUserId(user.uid) };
      if (roles) { amplitude.logEvent('Updates roles', { roles }) };
    }

    return idTokenResult;
  }
}

export const subscriptionStatusIs = (roles, status) => {
  try {
    if (roles
        && roles.plans
        && roles.plans.starter
        && roles.plans.starter.status
        && roles.plans.starter.status === status) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export const trialStatusIs = (roles, status) => {
  try {
    if (roles
      && roles.plans
      && roles.plans.trial
      && roles.plans.trial.status
      && roles.plans.trial.status === status) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export const statusIsTrialorPaid = (roles) => {
  return (subscriptionStatusIs(roles, 'active') || trialStatusIs(roles, 'active') ) ? true : false;
}

export const statusIsTrialorPaidorTrialInactive = (roles) => {
  return (subscriptionStatusIs(roles, 'active') || trialStatusIs(roles, 'active') || trialStatusIs(roles, 'inactive')) ? true : false;
}

export const setTrialRoleActive = async (uid) => {
  return new Promise(async (resolve, reject) => {
    const setTrialRole = firebase.functions().httpsCallable('setTrialRole');
    await setTrialRole({uid});
    resolve();
  });
}