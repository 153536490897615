import database from '../firebase/firebase';

// NEW
export const startInitAccount = (uid) => {
  return (dispatch) => {
    return database.ref(`users/${uid}/account`).once('value').then(
      async (snapshot) => {
        let account = snapshot.val();
        if (account === null || account === undefined) { account = {firstName: '', lastName: '', email: '', company: '', uid: ''} }
        await dispatch(setAccount(account));
      }
    ).catch(
      (e) => { console.log('Error retrieving account from database: ', e) }
    );
  };
};


// NEW
const setAccount = (account) => ({
  type: 'SET_ACCOUNT',
  account
});

// NEW
export const startSetAccount = (account) => {
  return (dispatch) => {
    dispatch(setAccount(account));
  }
}

// NEW
const unsetAccount = () => ({
  type: 'UNSET_ACCOUNT'
});

// NEW
export const startUnsetAccount = () => {
  return (dispatch) => {
    dispatch(unsetAccount());
  }
}

// New
export const startSaveAccount = ({ firstName, lastName, email, uid }) => {
  return (dispatch) => {
    const updates = {
      firstName,
      lastName,
      email,
      uid
    }
    database.ref(`users/${uid}/account`).update(updates).then( async () => {
      await dispatch(setAccount({ ...updates }))
    }).catch(
      (e) => { console.log('Error saving account: ', e) }
    )
  }
}