import React, {useState} from 'react';
import { Grid, Typography, Button, CardContent, Card, TextField, Link, FormLabel, CircularProgress } from '@material-ui/core';
import { validateEmail } from '../../helpers/formValidation';
import { firebase } from '../../firebase/firebase';
import { accountPath } from '../../helpers/links';
import { connect } from 'react-redux';
import { startSaveAccount } from '../../actions/account';
import { amplitude } from '../../helpers/amplitude';
import { amplitudeFlag } from '../../flags/flags';
import { makeStyles } from '@material-ui/core/styles';
import { green, white } from '../../styles/styles.scss';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  wrapper: {
    position: 'relative',
    width: '100%'
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green,
    '&:hover': {
      backgroundColor: green,
      color: white
    },
    '&:disabled': {
      backgroundColor: green,
      color: white
    }
  }
}));


const UpdateEmail = ({dispatch, auth, account}) => {

  const classes = useStyles();
  const [email, setEmail] = useState(account.email);
  const [emailError, setEmailError] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const buttonClassname = clsx({
    [classes.buttonSuccess]: showSuccess,
  });

  const handleSubmit = () => {
    if (showSuccess) {
      return;
    }
    let errors = false;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors = true;
    }
    if (account.email === email) {
      console.log('Email has not been changed.');
      errors = true;
    }
    if (!errors) {
      submit();
    }
  }

  const submit = async () => {

    setButtonLoading(true);

    if (amplitudeFlag) {
      if (auth.uid) { amplitude.setUserId(auth.uid) };
      amplitude.logEvent('Attempt email update');
    }


    // 1. Set email in firebase auth
    var user = firebase.auth().currentUser;
    const success = await user.updateEmail(email)
    .then( async () => {
      console.log('Email successfully updated in db') 
      return true;
    })
    .catch( (error) => {
      var errorCode = error.code;
      if (errorCode === 'auth/invalid-email') {
        setSubmissionError('Could not update your email because that email is not valid.  Please choose a different email.');
      } else if (errorCode === 'auth/email-already-in-use') {
        setSubmissionError('Could not update your email because that email is in use by another user.  Please choose a different email.');
      } else if (errorCode === 'auth/requires-recent-login') {
        setSubmissionError('Could not update your email because your last sign in was too long ago.  Please sign out and sign in again before updating your email.');
      }
      setEmail(account.email);
      setButtonLoading(false);
      console.log(error);
    })

    // 2. If that worked, set email in account...
    if (success) {
      const updates = { ...account, email };
      console.log('updates: ', updates)
      dispatch(startSaveAccount(updates));


      // 3. And set email in stripe...
      const updateStripeEmail = firebase.functions().httpsCallable('updateStripeEmail');
      console.log('email: ', email);
      await updateStripeEmail({ email })
        .then(() => { 
          showSuccessMessage(true);
          return console.log('Email successfully updated in stripe') ;
        })
        .catch(() => {
          console.log('Could not save new email in stripe');
          setSubmissionError('Could not update your email used for billing purposes.  If this problem persists, please contact us using the Contact page.');
        })
    }

    setButtonLoading(false);
  }


  const showSuccessMessage = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000)
  }

  return (
    <div className="get-started">
      <Card className="intro-card">
        <CardContent>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Update your email
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                Your new email will be used both for sign-in and billing.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                label="Email"
                onChange={(e) => { setEmail(e.target.value); setEmailError(false) }}
                variant="outlined"
                // required
                fullWidth
                helperText={emailError ? 'Please enter a valid email address' : ''}
                error={!!emailError}
              />
            </Grid>
                <Grid item xs={12}>
                  <div className={classes.wrapperContainer}>
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => { handleSubmit() }}
                        disabled={buttonLoading}
                        className={buttonClassname}
                      >
                        {showSuccess ? 'Success' : 'Update email'}
                      </Button>
                      {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Link href={accountPath} variant="body2">
                    View your account
                  </Link>
                </Grid>
            <Grid item xs={12}>
              {submissionError ? <FormLabel value={submissionError} error={true}>{submissionError}</FormLabel> : ''}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(UpdateEmail);