import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, Grid, Typography, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { Create } from '@material-ui/icons';
import { chooseTemplatesPath } from '../helpers/links';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../routers/AppRouter';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  wrapperContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 16
  }
}));


const Welcome = ({auth, account}) => {
  const classes = useStyles();


  return (
    <div>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
          Welcome to SplashPad
        </Typography>
        <Typography align="center" color="textPrimary">
          Write your first 3 messages completely free.
        </Typography>
        <div className={classes.wrapperContainer}>
          <Button
            variant="contained"
            onClick={(e) => { history.push(chooseTemplatesPath()) }}
            color="primary"
            endIcon={<Create />}
            style={{ borderRadius: 25 }}
          >
            Start writing
          </Button>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(Welcome);