import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { startSetSaveSnackbar } from '../actions/saveSnackbar';
import { connect } from 'react-redux';


const SaveSnackbar = ({dispatch, saveSnackbar}) => {


  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(startSetSaveSnackbar(false));
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={saveSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Message saved"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    saveSnackbar: state.saveSnackbar
  }
}

export default connect(mapStateToProps)(SaveSnackbar);