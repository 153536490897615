import React, {useEffect, useState} from 'react';
import Editor from '../components/Editor';
import Toolbar from '../components/Toolbar';
import NotepadContainer from '../components/NotepadContainer';
import {Grid} from '@material-ui/core';
import CopySnackbar from './CopySnackbar';
import SaveSnackbar from './SaveSnackbar';
import ProductTour from './ProductTour';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { startInitCategories, startUnsetCategories, setCategories } from '../actions/categories';
import { startSetExpandedBox } from '../actions/expandedBox';
import { startSetActiveSuggestion } from '../actions/activeSuggestion';
import { startSetHighlightMode } from '../actions/highlightMode';
import { startSetMessageContent } from '../actions/message';
import { retrieveTemplateContent } from '../helpers/templatesContent'
import { retrieveMessage } from '../helpers/messages'
import LoadingPage from './LoadingPage';
import { startInitChangesSinceSave } from '../actions/changesSinceSave';
import { Prompt } from 'react-router-dom';
import keyboardJS from 'keyboardjs';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { startSetActiveCategories } from '../actions/activeCategories';
import { filterToActiveCategories, removeCategoriesWithoutSuggestion } from '../helpers/categories';
import { startSetHideEditor } from '../actions/hideEditor';
import {returnCategoryOrder} from '../helpers/categories';
import {retrieveTemplateAbout} from '../helpers/templatesAbout';
import { getStartedTemplateId } from '../helpers/constants';
import { firebase } from '../firebase/firebase';
import { signinPath } from '../helpers/links';
import { history } from '../routers/AppRouter';
import MessageNotAvailable from './MessageNotAvailable';
import Fallback from './Fallback';
import { destroyJqueryHighlightPlugin } from '../helpers/highlightText';
import { initJqueryHighlightPluginToNone } from '../helpers/highlightText';
import { startSetMessageLastUpdated } from '../actions/messageLastUpdated';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  })
)


const Workspace = ({ match, dispatch, auth, highlightMode, categories, activeCategories, changesSinceSave, canUndo, canRedo, messageLastUpdated}) => {
  const classes = useStyles();
  const {templateId, getstarted, messageId} = match.params;

  const [categoriesAreLoading, setCategoriesAreLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [initialHighlightModeIsLoading, setInitialHighlightModeIsLoading] = useState(true);
  const [categoryOrder, setCategoryOrder] = useState({});
  const [templateName, setTemplateName] = useState('');
  const [messageNotAvailable, setMessageNotAvailable] = useState(false);
  const [fallback, setFallback] = useState(false);


  /* ----- GENERAL SET UP ----- */

  // Set up undo and redo
  useEffect(() => {
    //alt, shift, ctrl, etc must be lowercase
    keyboardJS.bind('mod > z', (e) => {
      if (canUndo) {
        dispatch(UndoActionCreators.undo()) 
      }
    });
    keyboardJS.bind('mod + shift > z', (e) => {
      if (canRedo) { dispatch(UndoActionCreators.redo()) }
    });
  }, [canUndo, canRedo, dispatch]);

  useEffect(() => {
    if(!isLoading) {
      initJqueryHighlightPluginToNone();
    }
  }, [isLoading])

  // 2 seconds after messageLastUpdated is set, if nothing else has modified messageLastUpdated, init the jquery plugin
  useEffect(() => {
    setTimeout(() => {
      if (messageLastUpdated && Date.now() >= messageLastUpdated + 2000) {
        initJqueryHighlightPluginToNone();
        dispatch(startSetMessageLastUpdated(false));
      }
    }, 2000)
  }, [messageLastUpdated, dispatch])

  // Reset the workspace when the Workspace dismounts (when the user leaves the page).
  useEffect(() => {
    return () => {
      dispatch(startUnsetCategories());
      dispatch(startSetExpandedBox(false));
      dispatch(startSetActiveSuggestion(false));
      dispatch(startSetActiveCategories({}));
      dispatch(startSetHighlightMode('off'));
      dispatch(startSetMessageContent({ subject: '', body: '', templateId: '' }));
      dispatch(startInitChangesSinceSave());
      dispatch(startSetHideEditor(false));
      destroyJqueryHighlightPlugin();
      try { keyboardJS.reset() }
      catch{ console.log('Could not unbind key bindings') };
    }
  }, [dispatch])


/* ----- SET UP WHEN __NOT__ IN GETSTARTED MODE----- */

  // If not authenticated, throw the fallback page
  useEffect(() => {
    if (!getstarted && !auth.uid) {
      history.push(`${signinPath}${history.location.pathname}`);
    }
  })

  // Load categories to store
  useEffect(() => {
    if (!getstarted && auth.uid && categoriesAreLoading) {
      const fetchData = async () => {
        await dispatch(startInitCategories());
        setCategoriesAreLoading(false);
      }
      fetchData();
    }
  }, [dispatch, categoriesAreLoading, auth, getstarted]);

  // If we are NOT in getStarted mode, retrieve the message and put it in the message content 
  useEffect(() => {
    if (!getstarted && auth.uid && !categoriesAreLoading) {
      const fetchData = async () => {
        let message;
        try {
          message = await retrieveMessage(auth.uid, messageId);
        } catch(error) {
          setIsLoading(false);
          setMessageNotAvailable(true);
        }
        if (message) {
          dispatch(startSetMessageContent(message));
          /* Clear history so that I can never undo to the initial state */
          dispatch(UndoActionCreators.clearHistory());
          const templateAbout = await retrieveTemplateAbout(message.templateId);
          setTemplateName(templateAbout.name);
          const template = await retrieveTemplateContent(message.templateId);
          const categoriesWithSuggestions = removeCategoriesWithoutSuggestion(categories);
          const activeCategories = filterToActiveCategories(template.selectedCategories, categoriesWithSuggestions);
          const categoryOrder = returnCategoryOrder(activeCategories, template.body);
          setCategoryOrder(categoryOrder);
          await dispatch(startSetActiveCategories(activeCategories));
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [dispatch, messageId, getstarted, auth.uid, categoriesAreLoading, categories])

  // If we are NOT in getStarted mode and the DOM and categories have loaded, initialize highlights
  useEffect(() => {
    if (!getstarted && isLoading === false && categoriesAreLoading === false && initialHighlightModeIsLoading === true && highlightMode === 'off') {
      const initHighlightMode = async () => {
        // await turnOnHighlights(activeCategories, highlightMode);
        // await dispatch(startSetHighlightMode('highlight'));
        setInitialHighlightModeIsLoading(false);
      }
      initHighlightMode();
    }
  }, [dispatch, isLoading, initialHighlightModeIsLoading, activeCategories, highlightMode, getstarted, categoriesAreLoading])

  // If we are NOT in getstarted mode, add prompt before leaving page (by navigating with browser)
  // useEffect(() => {
  //   if (!isLoading && changesSinceSave > 0 && !getstarted && !!auth.uid) {
  //     console.log('Inside useEffect: ')
  //     window.onbeforeunload = function () {
  //       return 'Are you sure you want to leave?  Your changes have not been saved.';
  //     };
  //   }
  // }, [isLoading, auth, changesSinceSave, getstarted]);





  /* ----- SET UP WHEN IN GETSTARTED MODE----- */

  // If we are in getstarted mode with templates, retrieve the template and set it to the message content
  useEffect(() => {
    if (getstarted) {
      const fetchData = async () => {
        const retrieveGetStartedContent = firebase.functions().httpsCallable('retrieveGetStartedContent');
        await retrieveGetStartedContent().then( async (result) => {
          const {filteredCategories, templateContent} = result.data;
          if (filteredCategories instanceof Error) {
            console.log('Error: filteredCategories cannot be found', filteredCategories);
            // Push to fallback page and log event
          } else if (templateContent instanceof Error) {
            console.log('Error: templateContent cannot be found', templateContent);
            // Push to fallback page and log event
          } else {
            const message = { subject: templateContent.subject, body: templateContent.body, templateId: getStartedTemplateId }
            dispatch(startSetMessageContent(message));
            dispatch(startSetHideEditor(true));
            const categoryOrder = returnCategoryOrder(filteredCategories, templateContent.body);
            await setCategoryOrder(categoryOrder);
            await dispatch(setCategories(filteredCategories));
            await dispatch(startSetActiveCategories(filteredCategories));
            setIsLoading(false);
          }
        }).catch((error) => {
          setFallback(true);
          console.log('Error: Cannot retrieve getstarted content', error);
        })

        // const template = await retrieveTemplateContent(templateId);
        // const message = { subject: template.subject, body: template.body, templateId }
        // dispatch(startSetMessageContent(message));
        // const activeCategories = filterToActiveCategories(template.selectedCategories, categories);
        // dispatch(startSetHideEditor(true));
        // await dispatch(startSetActiveCategories(activeCategories));
        // setIsLoading(false);
      }
      fetchData();
    }
  }, [templateId, dispatch, getstarted, categoriesAreLoading])



  if (isLoading) {
    return <LoadingPage />
  } else if (messageNotAvailable) {
    return <MessageNotAvailable navbar={true} />
  } else if (fallback) {
    return <Fallback />
  } else {
    return (
      <div id='workspace'>
        <Toolbar templateName={templateName} getStarted={getstarted === 'getstarted'}/>
        <CopySnackbar />
        <SaveSnackbar />
        <Prompt
          when={changesSinceSave > 0 && !getstarted && !!auth.uid}
          message={location => 'Are you sure you want to leave?  Your changes have not been saved.'}
        />
        <div className="workspace">
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={6}>
              <Editor categoryOrder={categoryOrder} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NotepadContainer />
            </Grid>
          </Grid>
          {getstarted === 'getstarted' ? <ProductTour /> : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    highlightMode: state.highlightMode,
    categories: state.categories,
    changesSinceSave: state.changesSinceSave,
    canUndo: state.message.past.length > 0,
    canRedo: state.message.future.length > 0,
    activeCategories: state.activeCategories,
    hideEditor: state.hideEditor,
    messageLastUpdated: state.messageLastUpdated
  }
}

export default connect(mapStateToProps)(Workspace);