import { firebase } from '../firebase/firebase';
import { startInitTrialActivity } from '../actions/trialActivity';

export const updateTrialMessagesCreated = async (uid, dispatch) => {
  const updateTrialActivityMessagesCreated = firebase.functions().httpsCallable('updateTrialActivityMessagesCreated');
  try {
    await updateTrialActivityMessagesCreated({ uid });
    await dispatch(startInitTrialActivity(uid));
  } catch (error) {
    console.log({ error });
  }
}