import React, {useState} from 'react';
import { connect } from 'react-redux';
import { amplitude } from '../helpers/amplitude';
import { amplitudeFlag } from '../flags/flags';
import moment from 'moment';
import { Button, CircularProgress } from '@material-ui/core';
import { history } from '../routers/AppRouter';
import { workspacePath, getStartedWorkspacePath } from '../helpers/links';
import { trialStatusIs } from '../helpers/auth';
import { startSaveMessage } from '../actions/messages';
import { Create } from '@material-ui/icons';
import { updateTrialMessagesCreated } from '../helpers/trialActivity';
import { makeStyles } from '@material-ui/core/styles';
import { primary } from '../styles/styles.scss';
import { createMessageObject } from '../helpers/messages';

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // width: '100%'
  },
  wrapper: {
    position: 'relative',
    width: '100%'
  },
  buttonProgress: {
    color: primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


const CreateMessageButton = ({dispatch, auth, account, getStarted, entry}) => {

  const [buttonLoading, setButtonLoading] = useState(false);
  const classes = useStyles();


  const handleClick = async (entry) => {

    setButtonLoading(true);


    if (getStarted) {
      if (amplitudeFlag) {
        amplitude.logEvent('Open getstarted message', { templateId: entry[0] });
      }
      history.push(getStartedWorkspacePath(entry[0]));
    } else if (auth.uid) {
      const message = await createMessageObject(entry);
      if (trialStatusIs(auth.roles, 'active')) {
        await updateTrialMessagesCreated(auth.uid, dispatch);
      }
      await dispatch(startSaveMessage(auth.uid, message.messageId, message));
      if (amplitudeFlag) {
        if (auth.uid) { amplitude.setUserId(auth.uid) };
        const formattedMessage = {
          ...message,
          lastSaved: message.lastSaved ? moment(message.lastSaved).format("MMM Do YYYY") : '',
          created: message.created ? moment(message.created).format("MMM Do YYYY") : ''
        }
        if (message) { amplitude.logEvent('Create message from template', { message: formattedMessage }) };
      }
      // history.push(workspacePath(message.messageId));
      window.location.href = workspacePath(message.messageId).substring(1);
    } else {
      console.log('Cannot create message because not logged in');
    }

    setButtonLoading(false);
  }

  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 25 }}
          onClick={() => handleClick(entry)}
          endIcon={<Create />}
          disabled={buttonLoading}
        >
          Start writing
        </Button>
        {buttonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account
  }
}

export default connect(mapStateToProps)(CreateMessageButton);